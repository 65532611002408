// ANGULAR
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';

import { ApiService, MessageService } from '@fm/services';
import { Tooltip } from '@fmlib/interfaces';

import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor';
import { DirectiveModule } from 'projects/manage/src/app/directives/directives.module';
import { link } from 'd3';

@Component({
	standalone: true,
	imports: [CommonModule, FormsModule, NgxEditorModule, DirectiveModule],
	selector: 'lib-tooltip-editor',
	templateUrl: './tooltip-editor.component.html',
	styleUrls: ['./tooltip-editor.component.less'],
})
export class TooltipEditorComponent implements OnChanges, OnDestroy {
	@Input() tooltip: Tooltip;

	@Output() update = new EventEmitter();

	urlPattern =
		/^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

	view;

	editor: Editor;
	toolbar: Toolbar = [
		// default value
		['link'],
		['bold', 'italic'],
		['underline', 'strike'],
		// ['code', 'blockquote'],
		['ordered_list', 'bullet_list'],
		[{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],

		// or, set options for link:
		//[{ link: { showOpenInNewTab: false } }, 'image'],
		// ['text_color', 'background_color'],
		['align_left', 'align_center', 'align_right', 'align_justify'],
		// ['horizontal_rule', 'format_clear'],
	];

	constructor(
		public dialog: MatDialog,
		private FM_Api: ApiService,
		private FM_Messsage: MessageService
	) {}

	ngOnChanges(): void {
		this.view = {
			showEditor: false,
			failed: false,
		};

		this.editor = new Editor();

		this.initTooltip();
	}

	initTooltip(): void {
		if (this.tooltip.draft === '' && this.tooltip.tooltip === '') {
			this.showEditor();
		}
	}

	// EDITOR
	showEditor(): void {
		this.view.showEditor = true;
	}

	clearEditor(): void {
		this.view.showEditor = true;
		this.tooltip.draft = '';
	}

	checkDraft(): void {
		if (this.tooltip.draft === '<p></p>') {
			this.tooltip.draft = '';
		}
	}

	editPublished(): void {
		this.tooltip.draft = this.tooltip.tooltip;
		this.tooltip.tooltip = '';
	}

	// SAVE/DELETE
	publish(): void {
		this.view.showEditor = false;

		const temp = { link: this.tooltip.link, tooltip: this.tooltip.draft, draft: '' };

		this.FM_Api.connect('tooltips')
			.put({ id: this.tooltip.id }, temp)
			.subscribe((response) => {
				this.tooltip = response;
				this.update.emit(response);
				this.FM_Messsage.addSuccessMessage('Your tooltip has been published');
			});
	}

	clearPublished(): void {
		this.tooltip.tooltip = '';
		this.initTooltip();

		/*
		const temp = { tooltip: '' };
		this.FM_Api.connect('tooltips')
			.put({ id: this.tooltip.id }, temp)
			.subscribe((response) => {
				this.tooltip = response;
				this.update.emit(response);
				this.FM_Messsage.addSuccessMessage('Your tooltip has been unpublished');
				this.initTooltip();
			});
			*/
	}

	save(): void {
		const temp = {
			draft: this.tooltip.draft,
			tooltip: this.tooltip.tooltip,
			link: this.tooltip.link,
		};

		this.FM_Api.connect('tooltips')
			.put({ id: this.tooltip.id }, temp)
			.subscribe((response) => {
				this.tooltip = response;
				this.update.emit(response);
				this.FM_Messsage.addSuccessMessage('Your tooltip has been saved');
			});
	}

	/*
	delete(): void {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			panelClass: 'modal-confirm',
			data: {
				message: 'DELETE_TOOLTIP',
			},
		});

		dialogRef.afterClosed().subscribe((results) => {
			if (results) {
				this.FM_Api.connect('tooltips').delete({ id: this.tooltip.id }, () => {
					this.list.initSearch();
				});
			}
		});
	}
		*/

	// make sure to destory the editor
	ngOnDestroy(): void {
		this.editor.destroy();
	}
}
