import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GoogleMap, MapCircle } from '@angular/google-maps';
import { TranslateService } from '@ngx-translate/core';

import { ProfileType } from '@fmlib/enums';
import { ApiService, GlobalService, MapsService, SearchService, UtilsService } from '@fm/services';
import { GlobalStore, SearchParams } from '@fmlib/interfaces';

import { cloneDeep } from 'lodash-es';

@Component({
	selector: 'nearby',
	templateUrl: './nearby.component.html',
})
export class NearbyComponent implements OnInit {
	@Input() obj;
	@Input() objtype: ProfileType;

	global: GlobalStore;

	ProfileType = ProfileType;

	geo;
	circle;
	map;
	filters;

	mainPosition: google.maps.LatLng;
	search;
	selected;
	settings;

	distanceOptions: any[];
	typeOptions: any[];

	filteredOptions: Observable<any[]>;
	myControl = new FormControl();

	@ViewChild(GoogleMap) gamap!: GoogleMap;
	@ViewChild(MapCircle) mapCircle!: MapCircle;

	@ViewChild('distanceInput', { static: false }) private distanceInput: ElementRef;

	constructor(
		private translate: TranslateService,
		private FM_Api: ApiService,
		private FM_Global: GlobalService,
		private FM_Maps: MapsService,
		private FM_Search: SearchService,
		private FM_Utils: UtilsService
	) {
		// MODALS NEEDS THE GLOBALS...
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.settings = {
			obj: this.obj,
			objtype: this.objtype,
		};

		this.filters = {
			isActive: true,
		};

		this.myControl.valueChanges
			.pipe(
				debounceTime(400),
				map((value) => this._filter(value))
			)
			.subscribe();

		this.typeOptions = [
			{
				id: ProfileType.BEACON,
				name: this.translate.instant('BEACONS'),
			},
			{
				id: ProfileType.SITE,
				name: this.translate.instant('SITES'),
			},
		];

		if (this.FM_Global.getSetting('smart-connect')) {
			this.typeOptions.push({
				id: ProfileType.NFC,
				name: this.translate.instant('NFCS'),
			});
		}

		// ONLY ON DEBUG FOR NOW
		if (this.global.config.isDebug) {
			this.typeOptions.push({
				id: ProfileType.GEOFENCE,
				name: this.translate.instant('GEOFENCES'),
			});
		}

		switch (this.objtype) {
			case ProfileType.BEACON:
				this.settings.endpoint = this.FM_Api.connect('sites');
				this.settings.searchtype = ProfileType.SITE;
				this.settings.iconLabel = 'B';
				break;
			case ProfileType.NFC:
				this.settings.endpoint = this.FM_Api.connect('sites');
				this.settings.searchtype = ProfileType.SITE;
				this.settings.iconLabel = 'N';
				break;
			case ProfileType.SITE:
				this.settings.endpoint = this.FM_Api.connect('beacons');
				this.settings.searchtype = ProfileType.BEACON;
				this.settings.iconLabel = 'S';
				break;
			default:
			// do nothing
		}

		// SCOPE VARIABLES
		this.search = {
			isLoading: false,
			isFailed: false,
		};

		// SCOPE VARIABLES
		this.geo = {
			distance: 1000,
		};

		this.distanceOptions = [
			{
				id: 100,
				name: '100 m',
			},
			{
				id: 200,
				name: '200 m',
			},
			{
				id: 500,
				name: '500 m',
			},
			{
				id: 1000,
				name: '1 km',
			},
			{
				id: 10000,
				name: '10 km',
			},
			{
				id: 100000,
				name: '100 km',
			},
			{
				id: 500000,
				name: '500 km',
			},
		];

		if (this.obj.lat !== 0 && this.obj.lng !== 0) {
			this.mainPosition = new google.maps.LatLng(this.obj.lat, this.obj.lng);

			// INIT MAP
			this.map = {
				options: {
					center: this.mainPosition,
					zoom: this.FM_Maps.DEFAULT_ZOOM,
					tilt: 0,
					scrollwheel: false,
					mapTypeId: google.maps.MapTypeId.ROADMAP,
				},
				events: {},
			};

			// DRAW THE BEACON CIRCLE
			this.circle = {
				center: this.mainPosition,
				radius: this.geo.distance,
				options: {
					fillColor: '#aed',
					fillOpacity: 0.5,

					strokeColor: '#aed',
					strokeOpacity: 1,
					strokeWeight: 5,
				},
			};
		}

		this.initList();
	}

	updateMap(): void {
		this.updateBounds();
	}

	initMap(): void {
		// this.map.isLoading = true;
		this.map.isInvalid = false;
		this.map.markers = [];

		// SHOW THE MAIN MARKER
		if (this.obj.lat !== 0 && this.obj.lng !== 0) {
			this.mainPosition = new google.maps.LatLng(this.obj.lat, this.obj.lng);

			const marker = this.FM_Maps.getMarker(this.mainPosition, this.settings.iconLabel);

			this.map.markers.push(marker);

			this.circle.center = this.mainPosition;
		}

		// SHOW LIST
		this.search.list.forEach((item, index) => {
			if (item.lat !== 0 && item.lng !== 0) {
				let marker;

				if (this.settings.searchtype === 'geofence') {
					marker = {
						id: index + 100,
						label: index + 1 + ' ',
						position: {
							lat: item.vertex.x,
							lng: item.vertex.y,
						},
						options: {},
					};
				} else {
					marker = {
						id: index + 100,
						label: index + 1 + ' ',
						position: {
							lat: item.lat,
							lng: item.lng,
						},
						options: {},
					};
				}

				this.map.markers.push(marker);
			}
		});

		this.updateMap();
	}

	isSelected(itemId: string): boolean {
		return this.selected.some(({ id }) => id === itemId);
	}

	loadList(): void {
		if (this.search.isLoading || this.search.page > this.search.pages) return;

		this.search.isFailed = false;
		this.search.isLoading = true;

		const params: SearchParams = {
			page: this.search.page,
			select: 'name lat lng vertex siteName assetName lastSeen',
			conditions: {},
		};

		delete this.filters.divisions;
		delete this.filters.division;

		if (this.filters.divisionFilter) {
			if (this.settings.searchtype === ProfileType.SITE) {
				this.filters.divisions = cloneDeep(this.filters.divisionFilter);
			} else {
				this.filters.division = cloneDeep(this.filters.divisionFilter);
			}

			delete this.filters.divisionFilter;
		}

		if (this.filters) {
			params.conditions = cloneDeep(this.filters);
		}

		// FORMAT THE PARAMS
		this.FM_Search.formatFilters(params, this.filters);

		// IF WE SEARCH SAME TYPE EXCLUDE CURRENT ITEM
		if (this.settings.searchtype === this.settings.objtype && this.settings.obj.id !== 0) {
			params.conditions.id = { $ne: this.settings.obj.id };
		}

		//ADD NEARYBY FILTERS
		params.conditions.location = {
			$near: {
				$geometry: {
					type: 'Point',
					coordinates: [String(this.obj.lng), String(this.obj.lat)],
				},
				$maxDistance: this.geo.distance,
			},
		};

		this.settings.endpoint.query(
			params,
			(data) => {
				this.search.list = this.search.list.concat(data);
				this.search.count = this.search.list.length;
				this.search.isLoading = false;

				this.initMap();
			},
			() => {
				this.search.isFailed = true;
				this.search.isLoading = false;
			}
		);
	}

	initList(): void {
		this.selected = [];

		this.search.page = 1;
		this.search.pages = 1;
		this.search.list = [];
		this.search.count = 0;

		this.loadList();
	}

	filterType(f): void {
		this.settings.searchtype = f.id;
		this.settings.endpoint = this.FM_Api.connect(this.settings.searchtype + 's');
		this.initList();
	}

	filterStatus(status: boolean): void {
		this.filters.isActive = status;
		this.initList();
	}

	filterDistance(distance: number): void {
		this.geo.distance = distance;
		this.circle.radius = this.geo.distance;
		this.updateBounds();
		this.initList();
	}

	private _filter(value: any): void {
		if (value) {
			if (value.id) {
				this.filterDistance(value.id);
			} else {
				const filterInt = parseInt(value);

				if (filterInt) {
					this.filterDistance(filterInt);
				}
			}
		}
	}

	updateDistance(event: MatAutocompleteSelectedEvent): void {
		if (event.option.value && event.option.value.id) {
			this.distanceInput.nativeElement.blur();
		}
	}

	isFilterActive(f): boolean {
		return this.settings.searchtype === f.id;
	}

	updateBounds(): void {
		const bounds = new google.maps.LatLngBounds();
		bounds.union(this.mapCircle.getBounds());

		this.gamap.googleMap.fitBounds(bounds);
	}

	// MODAL ACTIONS
	listClick(item, index: number): void {
		if (!item.isSelected) {
			// CLEAR SELECTED
			const selected = this.search.list.find(({ isSelected }) => isSelected);

			if (selected) {
				selected.isSelected = false;
			}

			// SELECT IN LIST
			item.isSelected = true;

			// let m = this.map.markers[index + 1];
			// m.setAnimation(google.maps.Animation.BOUNCE);

			this.updateMap();
		} else {
			item.isSelected = false;
			this.updateMap();
		}
	}

	// MODAL ACTIONS
	markerClick(i, index: number): void {
		if (i.key > 0) {
			// CLEAR LIST SELECTED
			this.search.list.forEach((item) => {
				item.isSelected = false;
			});

			// SELECT IN LIST
			this.search.list[i.key - 1].isSelected = true;
		}
	}

	// MODAL ACTIONS
	goToItem(item): void {
		const route: string = this.FM_Utils.getNamePluralized(this.settings.searchtype);
		this.FM_Maps.goToItem(route, item.id);
	}

	displayFn(option): string {
		return option && option.name ? option.name : '';
	}
}
