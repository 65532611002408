import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { debounceTime } from 'rxjs';

import { ApiService, GlobalService } from '@fm/services';
import { ProfileType } from '@fmlib/enums';
import { GlobalStore, SearchList, SearchParams, Site, Zone } from '@fmlib/interfaces';

@Component({
	selector: 'location-dialog',
	templateUrl: './location-dialog.component.html',
	styleUrls: ['./location-dialog.component.less'],
})
export class LocationDialogComponent implements OnInit {
	global: GlobalStore;

	sites: SearchList;
	zones: SearchList;

	selected: {
		site: any;
		zone?: any;
	};

	filters;
	siteTypes;

	ProfileType = ProfileType;

	myControl = new FormControl();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { site: any; zone: any },
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<LocationDialogComponent>,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(GlobalService) private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.FM_Api.connect('sitetypes')
			.get({
				per_page: 20,
			})
			.subscribe((res) => {
				this.siteTypes = res;
			});

		this.filters = {};

		this.sites = {
			list: [],
		};
		this.zones = {
			list: [],
		};

		this.selected = {
			site: this.data.site,
			zone: this.data.zone,
		};

		this.initSites();

		// PRIME THE ZONE TOTAL
		this.zones.total = 0;

		if (this.selected.site) {
			this.initZones();
		}

		this.myControl.valueChanges.pipe(debounceTime(400)).subscribe((model) => {
			this.sites.qs = model;
			this.initSites();
		});
	}

	getSetting(setting: string): any {
		return this.FM_Global.getSetting(setting);
	}

	loadSites(): void {
		if (this.sites.isLoading || this.sites.page > this.sites.pages) return;

		this.sites.isLoading = true;

		const params: SearchParams = {
			page: this.sites.page,
			sort: 'name',
			select: '-zones',
			conditions: {
				isShared: false,
			},
		};

		if (this.sites.qs && this.sites.qs !== '') {
			params.conditions.searchText = { $regex: this.sites.qs.toLowerCase() };
		}

		if (this.filters.siteType !== 'undefined') {
			params.conditions.siteType = this.filters.siteType;
		}

		this.FM_Api.connect('sites').query(params, (data, headers) => {
			this.sites.list = this.sites.list.concat(data);
			this.sites.page = this.sites.page + 1;
			this.sites.pages = parseInt(headers.get('X-Pages-Total'), 10);
			this.sites.total = parseInt(headers.get('X-Records-Total'), 10);
			this.sites.isLoading = false;
		});
	}

	initSites(): void {
		this.sites.page = 1;
		this.sites.pages = 1;
		this.sites.total = 0;
		this.sites.list = [];

		this.loadSites();
	}

	loadZones(): void {
		if (this.zones.isLoading || this.zones.page > this.zones.pages) return;

		this.zones.isLoading = true;

		const params: SearchParams = {
			page: this.zones.page,
			sort: 'zoneName',
			populate: {
				path: 'zone',
				select: 'name isActive',
			},
			conditions: {
				site: this.selected.site.id,
			},
		};

		if (this.zones.qs && this.zones.qs !== '') {
			params.conditions.zoneName = {
				$regex: '\\b' + this.zones.qs,
				$options: 'i',
			};
		}

		this.FM_Api.connect('sitezones').query(params, (data, headers) => {
			this.zones.list = this.zones.list.concat(data);
			this.zones.page = this.zones.page + 1;
			this.zones.pages = parseInt(headers.get('X-Pages-Total'), 10);
			this.zones.total = parseInt(headers.get('X-Records-Total'), 10);
			this.zones.isLoading = false;
		});
	}

	initZones(): void {
		this.zones.qs = '';

		this.zones.page = 1;
		this.zones.pages = 1;
		this.zones.total = 0;
		this.zones.list = [];

		this.loadZones();
	}

	selectSite(s: Site): void {
		this.selected = {
			site: s,
		};

		this.initZones();
	}

	selectZone(z: Zone): void {
		this.selected.zone = z;
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close(this.selected);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
