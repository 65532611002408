import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import {
	ApiService,
	DataService,
	GlobalService,
	MessageService,
	ProfileService,
} from '@fm/services';
import { AddressType, ModalSizes, ProfileType } from '@fmlib/enums';
import { GlobalStore } from '@fmlib/interfaces';

import { EMPTY, catchError } from 'rxjs';

import { SharedModule } from '../../shared/shared.module';
import { AddressListComponent } from '../../shared/address-list/address-list.component';
import { ProfileBasicsComponent } from '../../profile/profile-basics/profile-basics.component';
import { SelectStatesDialogComponent } from '../select-states/select-states.component';

@Component({
	standalone: true,
	imports: [SharedModule, ProfileBasicsComponent, AddressListComponent],
	selector: 'create-basic-dialog',
	templateUrl: './create-basic-dialog.component.html',
})
export class CreateBasicDialogComponent implements OnInit {
	global: GlobalStore;
	obj;
	profile;
	regions: any[];
	settings;

	AddressType = AddressType;
	ProfileType = ProfileType;

	constructor(
		@Inject(MatDialog) private dialog: MatDialog,
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<CreateBasicDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: { objtype: ProfileType; selected: any },
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(DataService) private FM_Data: DataService,
		@Inject(GlobalService) private FM_Global: GlobalService,
		@Inject(MessageService) private FM_Message: MessageService,
		@Inject(ProfileService) private FM_Profile: ProfileService
	) {}

	ngOnInit(): void {
		this.global = this.FM_Global.get();

		this.profile = {
			type: this.data.objtype,
			isLoading: false,
			isFailed: false,
		};

		this.settings = {
			endpoint: this.FM_Api.connect(this.profile.type + 's'),
		};

		if (this.data.selected) {
			this.obj = this.FM_Data.clone(this.data.selected);
			this.profile.title = 'EDIT_' + this.profile.type.toUpperCase();
		} else {
			this.obj = this.FM_Profile.getDefault(this.profile.type);
			this.profile.title = 'ADD_' + this.profile.type.toUpperCase();
		}

		if (this.profile.type === ProfileType.DISTRICT) {
			this.profile.isLoading = true;

			this.FM_Api.connect('regions').query(
				{
					page: 1,
					per_page: 1000,
					sort: 'name',
				},
				(data) => {
					this.regions = data;
					this.profile.isLoading = false;
				}
			);
		} else {
			this.profile.isLoading = false;
		}
	}

	saveComplete(response, message): void {
		this.FM_Message.addSuccessMessage(message);
		this.dialogRef.close(response);
		setTimeout(() => {
			this.profile.isBusy = false;
		}, 200);
	}

	saveError(): void {
		this.FM_Message.addErrorMessage('REQUEST_ERROR');
		setTimeout(() => {
			this.profile.isBusy = false;
		}, 200);
	}

	save(): void {
		this.profile.isBusy = true;

		// PREPARE THE SAVE
		let temp: any = {
			name: this.obj.name,
			isActive: this.obj.isActive,
			description: this.obj.description,
			tags: this.obj.tags,
		};

		if (this.obj.region) {
			temp.region = this.obj.region;
		}

		if (this.obj.stateAbbr) {
			temp.stateAbbr = this.obj.stateAbbr;
		}

		// FLATTENT THE DATA
		temp = this.FM_Data.flattenData(temp);

		// SAVE OR UPDATE
		if (!this.obj.id) {
			this.settings.endpoint.post(
				temp,
				(response) => {
					this.saveComplete(response, 'CREATED_' + +this.profile.type.toUpperCase());
				},
				() => {
					this.saveError();
				}
			);
		} else {
			this.settings.endpoint
				.put({ id: this.obj.id }, temp)
				.pipe(
					catchError(() => {
						this.saveError();
						return EMPTY;
					})
				)
				.subscribe((response) => {
					this.saveComplete(response, 'UPDATED_' + +this.profile.type.toUpperCase());
				});
		}
	}

	compareIds(a, b): boolean {
		const test = a && b ? a.id === b.id : a === b;
		return test;
	}

	addState(): void {
		const dialogRef = this.dialog.open(SelectStatesDialogComponent, {
			panelClass: ModalSizes.Select,
			data: {
				selected: this.obj.stateAbbr,
			},
		});

		dialogRef.afterClosed().subscribe((selected) => {
			if (selected) {
				this.obj.stateAbbr = selected;
			}
		});
	}

	removeState(s): void {
		const index = this.obj.stateAbbr.indexOf(s);
		this.obj.stateAbbr.splice(index, 1);
	}

	// SAVE
	ok(form): void {
		if (form.valid) {
			this.save();
		} else {
			this.FM_Message.addErrorMessage('INVALID_FORM');
		}
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
