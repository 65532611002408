import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { ProfileType } from '@fmlib/enums';
import { ApiService, GlobalService } from '@fm/services';
import { FMImage, GlobalStore } from '@fmlib/interfaces';

import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FmLoaderComponent } from '../../shared/fm-loader/fm-loader.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	imports: [CommonModule, FmLoaderComponent, TranslateModule],
	selector: 'upload-cdn-image',
	templateUrl: './upload-cdn-image.component.html',
})
export class UploadCdnImageComponent implements OnInit {
	@Input() obj;
	@Input() objtype: ProfileType;

	@Input() saveImages = true;

	global: GlobalStore;
	allowMultiple: boolean;
	isBusy: boolean;

	constructor(
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private FM_Api: ApiService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.allowMultiple =
			this.objtype === ProfileType.ASSET || this.objtype === ProfileType.GATEWAY;
	}

	getCDNLink(img: FMImage): string {
		return this.global.cdn.external + img.pathFragment;
	}

	getFilename(file: string): string {
		return file.substring(0, file.lastIndexOf('_')) + file.substring(file.lastIndexOf('.'));
	}

	startUpload(): void {
		this.isBusy = true;
		this.renderer.addClass(this.elementRef.nativeElement, 'inprogress');
	}

	stopUpload(): void {
		this.isBusy = false;
		this.renderer.removeClass(this.elementRef.nativeElement, 'inprogress');
	}

	uploadImages(e: Event): void {
		const files = (e.target as HTMLInputElement).files;
		if (files) {
			const promises = [];

			this.startUpload();

			Array.from(files).forEach((f: File) => {
				const r = new FileReader();

				// ADD PROMISE
				promises.push(
					new Promise((resolve) => {
						// LOAD IMAGE
						r.onloadend = (): void => {
							this.FM_Api.upload('files', f)
								.pipe(
									catchError(() => {
										resolve(null);
										return EMPTY;
									})
								)
								.subscribe((response) => {
									if (response) {
										const uploadedDate: string = new Date().toUTCString();
										const image = {
											uploaded: uploadedDate,
											filename: response.filename,
											pathFragment: response.pathFragment,
										};

										if (this.allowMultiple) {
											this.obj.images = [image];
										} else {
											this.obj.image = image;
										}
									}

									resolve(null);
								});
						};
					})
				);

				r.readAsDataURL(f);
			});

			Promise.all(promises).then(() => {
				// IF OBJ AUTOSAVE
				if (this.obj.id && this.saveImages) {
					const temp: any = {};

					// SET THE TEMP
					if (this.allowMultiple) {
						temp.images = this.obj.images;
					} else {
						temp.image = this.obj.image;
					}

					// SET THE ENDPOINT
					const endpoint: string =
						this.objtype === ProfileType.COMPANY ? 'companies' : this.objtype + 's';

					this.FM_Api.connect(endpoint)
						.put({ id: this.obj.id }, temp)
						.subscribe(() => {
							this.stopUpload();
						});
				} else {
					this.stopUpload();
				}
			});
		}
	}
}
