import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { AdminProfileDialogComponent } from '../adminuser/admin-profile/admin-profile-dialog.component';
import { BatchAddDialogComponent } from '../dialogs/batch/batch-add-dialog.component';
import { NotificationsAddDialogComponent } from '../notifications/notifications-add-dialog.component';
import { NotificationsDialogComponent } from '../notifications/notifications-dialog.component';
import { SelectDialogComponent } from '../dialogs/select-dialog/select-dialog.component';

import { ModalSizes, ProfileType } from '@fmlib/enums';
import {
	ApiService,
	AppService,
	AppView,
	DataService,
	GlobalService,
	ListData,
	MenuService,
	MessageService,
} from '@fm/services';
import { GlobalStore, Language, Notification } from '@fmlib/interfaces';

import { forkJoin } from 'rxjs';

import { SharedModule } from '../shared/shared.module';
import { ControlPanelComponent } from './control-panel/control-panel.component';

@Component({
	standalone: true,
	imports: [SharedModule, ControlPanelComponent],
	selector: 'fm-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.less'],
	encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
	ProfileType = ProfileType;

	global: GlobalStore;
	languages: Language[];
	brandingOptions: ListData[];
	groups: any[];

	currentPanel: string;

	hasMessage: boolean;

	get view(): AppView {
		return this.FM_App.view;
	}

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private FM_App: AppService,
		private FM_Api: ApiService,
		private FM_Data: DataService,
		private FM_Global: GlobalService,
		private FM_Menu: MenuService,
		private FM_Messages: MessageService
	) {
		this.global = FM_Global.get();
	}

	ngOnInit(): void {
		this.languages = this.FM_Global.getLanguages();
		this.brandingOptions = this.FM_Data.brandingOptions();

		// FIRST CHECK
		if (this.global.authenticated) {
			this.FM_Api.connect('groups').query({}, (response) => {
				this.groups = response;
			});

			if (this.FM_Global.hasPermission('notification', 'view')) {
				this.initNotifs();
			}
		}

		// IF COMPANY IS CHANGED
		this.FM_Global.companyChange().subscribe(() => {
			if (this.FM_Global.hasPermission('notification', 'view')) {
				this.initNotifs();
			}
		});

		this.FM_Messages.messageUpdate().subscribe((state) => {
			setTimeout(() => {
				this.hasMessage = state;
			});
		});

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				this.closePanel();
			}
		});
	}

	getSetting(setting: string): any {
		return this.FM_Global.getSetting(setting);
	}

	// LOG GLOBAL SETTINGS
	logGlobal(): void {
		console.log('logGlobal', this.global);
	}

	// LOGOUT
	logout(): void {
		this.FM_Global.logout();
		this.closePanel();
	}

	setPermissions(): void {
		this.FM_Api.connect('permissions').query(
			{
				page: 1,
				per_page: '200',
				sort: 'resource',
				conditions: { group: this.global.group.id },
			},
			(response) => {
				const tempPermissions = {};
				response.forEach((item) => {
					tempPermissions[item.resource] = {
						actions: item.actions,
					};
				});

				this.global.permissions = tempPermissions;
				this.FM_Menu.update();
			}
		);
	}

	getEnvironment(env: string): string {
		let name: string;
		switch (env) {
			case 'production':
				name = 'NORTH_AMERICA';
				break;
			/*	
			case 'production-eu':
				name = 'EUROPEAN_UNION';
				break;
			*/
			default:
				name = env.toUpperCase();
		}

		return name;
	}

	toggleSetting(setting: string): void {
		const s = this.global.companysettings.find(({ key }) => key === setting);
		s.value = !s.value;
		this.FM_Menu.update();
	}

	updateSuper(): void {
		this.global.user.isSuper = !this.global.user.isSuper;
		this.FM_Menu.update();
	}

	updateBranding(): void {
		this.FM_Global.updateBranding();
	}

	// MANAGE NOTIFICATIONS
	loadNotifications(): void {
		if (this.global.user) {
			this.FM_Api.connect('notifications').query(
				{
					sort: '-created',
					conditions: {
						adminUser: this.global.user.id,
						isArchived: false,
					},
				},
				(data) => {
					// PUSH INTO GLOBAL OBJECT
					this.global.notifications.list = this.FM_Data.clone(data);

					// GET UNREAD COUNT
					this.global.notifications.unreadCount = this.global.notifications.list.filter(
						(item) => {
							return !item.isRead;
						}
					).length;
				},
				() => {
					console.error('error : notifications load');
				}
			);
		}
	}

	// ADD NOTIFICATION
	addNotification(): void {
		const dialogRef = this.dialog.open(NotificationsAddDialogComponent, {
			//
			panelClass: ModalSizes.Basic,
			data: {
				mode: 'add',
			},
		});

		dialogRef.afterClosed().subscribe((selected) => {
			if (selected) {
				this.loadNotifications();
			}
		});
	}

	// MANAGE NOTIFICATIONS
	manageNotifications(): void {
		const dialogRef = this.dialog.open(NotificationsAddDialogComponent, {
			//
			panelClass: ModalSizes.Basic,
			data: {
				mode: 'manage',
			},
		});

		dialogRef.afterClosed().subscribe((selected) => {
			if (selected) {
				this.loadNotifications();
			}
		});
	}

	viewNotifications(notif?: Notification): void {
		const dialogRef = this.dialog.open(NotificationsDialogComponent, {
			//
			panelClass: ModalSizes.Search,
			data: {
				mode: 'manage',
				notif: notif,
			},
		});

		dialogRef.afterClosed().subscribe((selected) => {
			if (selected) {
				this.loadNotifications();
			}
		});
	}

	clearNotification(notif: Notification): void {
		this.FM_Api.delete('notifications', notif.id).subscribe(() => {
			this.loadNotifications();
		});
	}

	deleteAllNotifications(): void {
		if (this.global.notifications.list) {
			this.FM_Api.delete('notification/clearall').subscribe(() => {
				this.global.notifications.list = [];
				this.closePanel();
			});
		}
	}

	clearUserSettings(): void {
		const obs = [];
		this.global.usersettings.forEach((item) => {
			obs.push(this.FM_Api.connect('usersettings').delete({ id: item.id }));
		});

		forkJoin(obs).subscribe(() => {
			this.global.usersettings = [];
		});
	}

	// BATCH ADD TO ENDPOINT
	batchAdd(): void {
		this.dialog.open(BatchAddDialogComponent, {
			panelClass: ModalSizes.Create,
		});
	}

	// DELETE ALL FROM ENDPOINT
	deleteAll(endpoint: string): void {
		this.FM_Api.connect(endpoint).query(
			{
				page: 1,
				per_page: 1000,
			},
			(data) => {
				data.forEach((item) => {
					this.FM_Api.connect(endpoint).delete({ id: item.id }).subscribe();
				});
			}
		);
	}

	async smsModal(): Promise<void> {
		this.closePanel();

		const { SmsDialogComponent } = await import(
			/* webpackPrefetch: true */
			'../dialogs/sms-dialog.component'
		);

		this.dialog.open(SmsDialogComponent, {
			panelClass: ModalSizes.Basic,
		});
	}

	async launchWizard(): Promise<void> {
		this.closePanel();

		const { WizardDialogComponent } = await import(
			/* webpackPrefetch: true */
			'../wizard/wizard-dialog.component'
		);

		this.dialog.open(WizardDialogComponent, {
			panelClass: ModalSizes.Create,
		});
	}

	/*
	deviceAuditing(): void {
		this.closePanel();

		this.dialog.open(UserdeviceDialogComponent, {
			panelClass: ModalSizes.Create,
		});
	}
	*/

	loadAdminProfile(): void {
		this.closePanel();

		this.dialog.open(AdminProfileDialogComponent, {
			panelClass: ModalSizes.Basic,
			data: {
				user: this.global.user,
			},
		});
	}

	loadCompanyModal(): void {
		this.closePanel();

		const dialogRef = this.dialog.open(SelectDialogComponent, {
			panelClass: ModalSizes.Select,
			data: {
				settings: { type: 'company' },
				selected: this.global.company,
			},
		});

		dialogRef.afterClosed().subscribe((selected) => {
			if (selected) {
				this.FM_Global.switchCompany(selected.id);
			}
		});
	}

	returnToRoot(): void {
		this.FM_Global.switchCompany(this.global.user.company);
	}

	// MARK ALL NOTIFICATIONS AS READ
	readAllNotifications(): void {
		const obs = [];
		if (this.global.notifications.list) {
			this.global.notifications.list.forEach((item) => {
				if (!item.isRead) {
					obs.push(
						this.FM_Api.connect('notifications').put({ id: item.id }, { isRead: true })
					);
				}
			});

			forkJoin(obs).subscribe(() => {
				this.global.notifications.list.forEach((item) => {
					item.isRead = true;
				});

				this.global.notifications.unreadCount = 0;
			});
		}
	}

	closePanel(): void {
		this.currentPanel = null;
	}

	setPanel(panel: string): void {
		if (this.currentPanel !== panel) {
			this.currentPanel = panel;

			if (panel === 'notifications') {
				this.readAllNotifications();
			}
		} else {
			this.closePanel();
		}
	}

	openNews(): void {
		this.view.showNews = true;
	}

	initNotifs(): void {
		this.global.notifications.list = [];
		this.global.notifications.unreadCount = 0;

		// SOCKET CONNECTIONS
		this.global.socket.emit('login', 'notif');

		this.global.socket.on('notif', () => {
			this.loadNotifications();
		});

		this.loadNotifications();
	}

	updatePanel($event: string): void {
		this.currentPanel = $event;
	}

	setLanguage(lang: Language): void {
		this.FM_Global.setLanguage(lang);
	}

	openHelp(path: string): void {
		const myform = document.createElement('form');
		myform.method = 'POST';
		myform.action = 'https://developer.footmarks.com/password';
		myform.style.display = 'none';
		myform.append('Content-Type', 'application/x-www-form-urlencoded');

		const passwordInput = document.createElement('input');
		passwordInput.type = 'hidden';
		passwordInput.name = 'password';
		passwordInput.value = '&0k+{n:O28m,S1m';
		myform.appendChild(passwordInput);

		const redirectInput = document.createElement('input');
		redirectInput.type = 'hidden';
		redirectInput.name = 'redirect';
		redirectInput.value = path; // '/docs';
		myform.appendChild(redirectInput);

		document.body.appendChild(myform);
		myform.submit();
	}

	featureRequest(): void {
		const params = {
			'name[first]': this.global.user.firstname,
			'name[last]': this.global.user.lastname,
			email: this.global.user.email,
		};

		const q = new URLSearchParams(params).toString();

		window.open(
			'https://form.jotform.com/223485438204153?' + q,
			'blank',
			`scrollbars=yes, toolbar=no, width=700, height=${window.innerHeight - 200}, top=${
				window.screenTop + 10
			}, left=${window.screenLeft + 10}`
		);
	}
}
