<section class="filter-panel">
	<div class="fm-sidebar fm-filterlist">
		<div class="filter-section">
			<div class="filter-group">
				<fm-filter class="fm-filter fixed">
					<h3 translate="FILTER_BY_TYPE"></h3>
					<div class="filter-wrap">
						<ul>
							<li
								class="fm-savedfilter"
								*ngFor="
									let filter of typeOptions;
									trackBy: 'index' | trackByProperty
								"
								(click)="filterType(filter)"
								[ngClass]="{ active: isFilterActive(filter) }">
								<a [routerLink]="">
									<span class="filter-status"><i class="fa fa-check"></i></span>
									{{ filter.name }}
								</a>
							</li>
						</ul>
					</div>
				</fm-filter>

				<!--
				<fm-filter class="fm-filter fixed">
					<h3 translate="FILTER_BY_STATUS"></h3>
					<div class="filter-wrap">
						<ul>
							<li class="fm-savedfilter" *ngFor="let status of statusFilters; trackBy: 'index' | trackByProperty" (click)="filterStatus(status.key)" [ngClass]="{ active: filters.isActive === status.key }">
								<a [routerLink]="">
									<span class="filter-status"><i class="fa fa-check"></i></span>
									{{ status.name }}
								</a>
							</li>
						</ul>
					</div>
				</fm-filter>
				-->

				<div class="fm-filter fixed">
					<h3 translate="SEARCH_RADIUS"></h3>

					<div class="filter-wrap">
						<div class="typeahead">
							<div class="field-editor">
								<input
									type="text"
									#distanceInput
									class="fm-input"
									placeholder="{{ 'ENTER_DISTANCE' | translate }}"
									matInput
									[formControl]="myControl"
									[matAutocomplete]="auto" />
								<mat-autocomplete
									#auto="matAutocomplete"
									[displayWith]="displayFn"
									(optionSelected)="updateDistance($event)">
									<mat-option
										*ngFor="let option of distanceOptions"
										[value]="option"
										>{{ option.name | translate }}</mat-option
									>
								</mat-autocomplete>
							</div>
						</div>
					</div>
				</div>

				<fm-filter class="fm-filter">
					<h3 translate="FILTER_BY_DIVISION"></h3>
					<filter-search
						#filter
						[(filter)]="filters.divisionFilter"
						api="divisions"
						(update)="initList()"></filter-search>
				</fm-filter>
			</div>
		</div>
	</div>
</section>

<section class="list-panel">
	<section class="fm-list-wrap select-mode">
		<div
			class="fm-message notice"
			*ngIf="search.list.length <= 0 && !search.isLoading && !search.isFailed">
			<span translate="NO_RESULTS"></span>
		</div>

		<div class="fm-list">
			<a
				[routerLink]=""
				class="fm-list-item"
				*ngFor="let item of search.list; index as i; trackBy: 'id' | trackByProperty"
				(click)="listClick(item, i)"
				[ngClass]="{ selected: item.isSelected }">
				<div class="list-index" [innerText]="i + 1"></div>

				<div class="list-cell fm-list-detail">
					<div class="detail-main">
						<span [innerText]="item.name"></span>
						<ul class="detail" *ngIf="settings.searchtype === ProfileType.BEACON">
							<li><span translate="LAT"></span> : {{ item.lat }}</li>
							<li><span translate="LNG"></span> : {{ item.lng }}</li>
							<li>
								<span translate="SITE"></span> : {{ item.siteName | emptyString }}
							</li>
							<li
								*ngIf="
									item.lastSeen && item.lastSeen !== '0001-01-01T00:00:00.000Z'
								">
								<span translate="LAST_SEEN"></span> :
								{{ item.lastSeen | customDateTime }}
							</li>
						</ul>
						<ul class="detail" *ngIf="settings.searchtype === ProfileType.NFC">
							<li><span translate="LAT"></span> : {{ item.lat }}</li>
							<li><span translate="LNG"></span> : {{ item.lng }}</li>
						</ul>
						<ul class="detail" *ngIf="settings.searchtype === ProfileType.SITE">
							<li><span translate="LAT"></span> : {{ item.lat }}</li>
							<li><span translate="LNG"></span> : {{ item.lng }}</li>
						</ul>
						<ul class="detail" *ngIf="settings.searchtype === ProfileType.GEOFENCE">
							<li><span translate="LAT"></span> : {{ item.vertex?.x }}</li>
							<li><span translate="LNG"></span> : {{ item.vertex?.y }}</li>
						</ul>
					</div>
					<span class="list-action" (click)="goToItem(item)" stop-propagation
						><i class="fa fa-external-link-alt"></i
					></span>
				</div>
			</a>
		</div>
	</section>

	<div class="panel-loader">
		<fm-loader [loading]="search.isLoading" [error]="search.isFailed"></fm-loader>
	</div>

	<!--<list-status [loading]="search.isLoading" [count]="search.count" [total]="search.total"></list-status>-->
</section>

<section class="detail-panel">
	<div class="fm-map">
		<div class="map-wrapper">
			<div class="map-cover" *ngIf="map.isLoading">
				<div class="map-message">
					<h4 translate="LOADING_MAP"></h4>
					<i class="fa fa-spinner fa-spin"></i>
				</div>
			</div>

			<div class="map-cover" *ngIf="map.isInvalid">
				<div class="map-message">
					<h4 translate="NO_MAP"></h4>
				</div>
			</div>

			<google-map width="100%" height="100%" [options]="map.options">
				<map-marker
					*ngFor="let m of map.markers; trackBy: 'index' | trackByProperty"
					[label]="m.label"
					[position]="m.position"></map-marker>
				<map-circle
					#mapCircle
					*ngIf="circle"
					[center]="circle.center"
					[radius]="circle.radius"
					[options]="circle.options"></map-circle>
			</google-map>
		</div>
	</div>
</section>
