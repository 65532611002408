import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { FiltersModule } from '../filter/filter.module';

import { AdminProfileDialogComponent } from '../adminuser/admin-profile/admin-profile-dialog.component';
import { CreateBasicDialogComponent } from './create-dialog/create-basic-dialog.component';

import { CreateSitetypeDialogComponent } from './create-sitetype-dialog.component';

import { FilterDialogComponent } from './filter-dialog.component';
import { GoogleDialogComponent } from './google-dialog.component';

import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { SelectExperiencesDialogComponent } from './select-experiences-dialog.component';
import { SelectMultiDialogComponent } from './select-multi-dialog/select-multi-dialog.component';

import { SelectZoneDialogComponent } from './select-zone/select-zone-dialog.component';

import { ProfilesDialogComponent } from './profiles-dialog.component';
import { ContactDialogComponent } from './contact-dialog.component';
import { CreateValidationDialogComponent } from './create-validation-dialog.component';
import { RawBTSDialogComponent } from './rawbts-dialog.component';

import { SmsDialogComponent } from './sms-dialog.component';

import { SelectStatesDialogComponent } from './select-states/select-states.component';

import { ConfirmDialogComponent } from '../confirm/confirm-dialog.component';
import { ConfirmDeleteDialogComponent } from '../confirm/delete/confirm-delete-dialog.component';
import { ConfirmDuplicateDialogComponent } from '../confirm/duplicate/confirm-duplicate-dialog.component';
import { ConfirmFreezeDialogComponent } from '../confirm/freeze/confirm-freeze-dialog.component';

import { LocationDialogComponent } from '../location/location-dialog/location-dialog.component';

import { PushDialogComponent } from '../push/push-dialog.component';
import { SettingsDialogComponent } from '../settings/settings-dialog.component';

import { CompanyQuickswitchComponent } from '../shared/company-quickswitch/company-quickswitch.component';
import { PayloadPreviewComponent } from '../shared/payload-preview/payload-preview.component';
import { PasswordValidateComponent } from '../shared/password-validate/password-validate.component';
import { ProfileBasicsComponent } from '../profile/profile-basics/profile-basics.component';

const components = [
	LocationDialogComponent,
	PushDialogComponent,
	SettingsDialogComponent,

	AdminProfileDialogComponent,
	ContactDialogComponent,

	CreateSitetypeDialogComponent,
	CreateValidationDialogComponent,

	ConfirmDialogComponent,
	ConfirmDeleteDialogComponent,
	ConfirmDuplicateDialogComponent,
	ConfirmFreezeDialogComponent,

	FilterDialogComponent,
	GoogleDialogComponent,

	ProfilesDialogComponent,

	RawBTSDialogComponent,

	SelectDialogComponent,
	SelectExperiencesDialogComponent,
	SelectMultiDialogComponent,

	SelectStatesDialogComponent,
	SelectZoneDialogComponent,
	SmsDialogComponent,
];

@NgModule({
	imports: [
		FiltersModule,
		SharedModule,

		// STANDALONE
		CreateBasicDialogComponent,
		CompanyQuickswitchComponent,
		PasswordValidateComponent,
		PayloadPreviewComponent,
		ProfileBasicsComponent,
	],
	declarations: components,
	exports: components,
})
export class DialogsModule {}
