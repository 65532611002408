import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { GlobalStore, SearchParams, TypeInfo } from '@fmlib/interfaces';
import { DataType, ProfileType } from '@fmlib/enums';

import { ApiService, GlobalService, MessageService, UtilsService } from '@fm/services';

import { catchError, map, shareReplay } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';

import { cloneDeep, isArray, isEqual, isObject, merge, sortBy, uniq } from 'lodash-es';

export interface ListData {
	id: string;
	name: string;
}

@Injectable({ providedIn: 'root' })
export class DataService {
	private global: GlobalStore;
	private beaconTypesData: any[];
	private nfcTypesData: any[];

	constructor(
		private http: HttpClient,
		private FM_Api: ApiService,
		private FM_Global: GlobalService,
		private FM_Messages: MessageService,
		private FM_Utils: UtilsService
	) {
		this.global = this.FM_Global.get();
	}

	clone(obj): any {
		return cloneDeep(obj);
	}

	merge(obj1, obj2): any {
		return merge(obj1, obj2);
	}

	getChanges(source, now): any {
		const changes = {};
		let prop: any = {};

		for (prop in now) {
			if (!source || source[prop] !== now[prop]) {
				// ARRAY CHECK
				if (isArray(now[prop])) {
					if (source[prop] === null || !isEqual(source[prop], now[prop])) {
						changes[prop] = now[prop];
					}
				}

				// OBJECT CHECK
				else if (isObject(now[prop])) {
					if (source[prop] === null || !isEqual(source[prop], now[prop])) {
						changes[prop] = now[prop];
					}
					/*
					// Recursion alert
					// c = getChanges(prev[prop], now[prop]);
					if (!isEmpty(c)) {
						changes[prop] = c;
					}
					*/
				} else {
					changes[prop] = now[prop];
				}
			}
		}

		return changes;
	}

	flattenData(obj): any {
		const c = this.clone(obj);

		// SET BEACONTYPE
		if (obj.beaconType) {
			c.beaconType = obj.beaconType.beaconType;
		}

		// SET BEACONTYPE
		if (obj.nfcType) {
			c.nfcType = obj.nfcType.nfcType;
		}

		// SET ASSET
		if (obj.asset) {
			c.asset = obj.asset.id;
		}

		// SET PAYLOAD
		if (obj.payload) {
			c.payload = obj.payload.id;
		}

		// SET DISTRICT
		if (obj.district) {
			c.district = obj.district.id;
		}

		// SET DIVISION
		if (obj.division) {
			c.division = obj.division.id;
		}

		// SET REGION
		if (obj.region) {
			c.region = obj.region.id;
		}

		// SET SITE
		if (obj.site) {
			c.site = obj.site.id;
		}

		// SET SITETYPE
		if (obj.siteType) {
			c.siteType = obj.siteType.id;
		}

		// SET ZONE
		if (obj.zone) {
			c.zone = obj.zone.id;
		}

		// FLATTEN LISTS
		if (obj.apps) {
			c.apps = obj.apps.map((o) => o.id);
		}

		if (obj.assets) {
			c.assets = obj.assets.map((o) => o.id);
		}

		if (obj.brands) {
			c.brands = obj.brands.map((o) => o.id);
		}

		if (obj.devices) {
			c.devices = obj.devices.map((o) => o.id);
		}

		if (obj.divisions) {
			c.divisions = obj.divisions.map((o) => o.id);
		}

		if (obj.groups) {
			c.groups = obj.groups.map((o) => o.id);
		}

		if (obj.partners) {
			c.partners = obj.partners.map((o) => o.id);
		}

		if (obj.sites) {
			c.sites = obj.sites.map((o) => o.id);
		}

		if (obj.steps) {
			const steps = [];
			obj.steps.forEach((item) => {
				if (item && item.id) {
					const o = {
						id: item.id,
						type: 'experience',
						action: item.action, // allow, block, branch
					};
					steps.push(o);
				}
			});

			c.steps = steps;
		}

		if (obj.suppliers) {
			c.suppliers = obj.suppliers.map((o) => o.id);
		}

		// FLATTEN TAGS
		if (obj.tags) {
			const tags = [];
			obj.tags.forEach((item) => {
				if (isObject(item)) {
					tags.push(item['text']);
				} else {
					tags.push(item);
				}
			});

			c.tags = tags;
		}

		if (obj.targetProfiles) {
			c.targetProfiles = obj.targetProfiles.map((o) => o.id);
		}

		if (obj.taxonomies) {
			c.taxonomies = obj.taxonomies.map((o) => o.id);
		}

		if (obj.zones) {
			c.zones = obj.zones.map((o) => o.id);
		}

		return c;
	}

	saveChanges(obj, temp, endpoint, settings): Promise<null> {
		return new Promise((resolve, reject) => {
			// SAVE OR UPDATE
			if (!obj.id || obj.isDuplicate) {
				endpoint.post(
					{ populate: settings.populate },
					temp,
					() => {
						resolve(null);
					},
					() => {
						this.FM_Messages.addErrorMessage('REQUEST_ERROR');
						reject();
					}
				);
			} else {
				endpoint
					.put(
						{
							id: obj.id,
							populate: settings.populate,
						},
						temp
					)
					.pipe(
						catchError(() => {
							this.FM_Messages.addErrorMessage('REQUEST_ERROR');
							reject();
							return EMPTY;
						})
					)
					.subscribe(() => {
						resolve(null);
					});
			}
		});
	}

	advertisementIntervals(): number[] {
		return [200, 400];
	}

	beaconEvents(): string[] {
		return ['enter', 'exit', 'idle'];
	}

	/**
	 *
	 * @returns list of numbers for beacon levels
	 */
	beaconLevels(): number[] {
		return [1, 2, 3, 4, 5, 6, 7, 8];
	}

	beaconRegions(): ListData[] {
		return [
			{
				id: 'AF21CFAA-7B9D-49DE-8CB2-0799AE810877',
				name: 'Region 1',
			},
			{
				id: 'E2C56DB5-CCCC-48D2-B060-D0F5A71096E0',
				name: 'Region 2',
			},
			{
				id: '106A6AA9-0412-4DF5-8E0A-FD0F8DD7C00E',
				name: 'Region 3',
			},
			{
				id: 'F337F556-FFFD-4D4D-8753-806DF03B06DA',
				name: 'Region 4',
			},
			{
				id: '778A0150-0646-42C9-B90D-71A192D297E7',
				name: 'Region 5',
			},
			{
				id: 'E2C56DB5-EEEE-48D2-B060-D0F5A71096E0',
				name: 'Region 6',
			},
			{
				id: 'AB48E9ED-363E-4F19-A0F0-5DE76927DE03',
				name: 'Region 7',
			},
			{
				id: 'E2C56DB5-AAAA-48D2-B060-D0F5A71096E0',
				name: 'Region 8',
			},
			{
				id: 'FD8681C7-7362-4F18-B9A0-4F27ED8B7357',
				name: 'Region 9',
			},
			{
				id: 'F42808DA-AACD-43EB-ACC7-8D26EEF41F12',
				name: 'Region 10',
			},
			{
				id: 'E2C56DB5-DFFB-48D2-B060-D0F5A71096E0',
				name: 'Region 11',
			},
			{
				id: 'AE2FD9B3-364B-4435-B940-84E85AA813A0',
				name: 'Region 12',
			},
		];
	}

	locationStability(): ListData[] {
		return [
			{ id: 'unspecified', name: 'UNSPECIFIED' },
			{ id: 'stable', name: 'STABLE' },
			{ id: 'portable', name: 'PORTABLE' },
			{ id: 'mobile', name: 'MOBILE' },
			{ id: 'roving', name: 'ROVING' },
		];
	}

	beaconTypes(): Promise<any> {
		return new Promise((resolve) => {
			if (this.beaconTypesData) {
				resolve(this.beaconTypesData);
			} else {
				this.FM_Api.connect('beacontypes').query({ page: 1, sort: 'name' }, (data) => {
					this.beaconTypesData = data;
					resolve(this.beaconTypesData);
				});
			}
		});
	}

	nfcTypes(): Promise<any> {
		return new Promise((resolve) => {
			if (this.nfcTypesData) {
				resolve(this.nfcTypesData);
			} else {
				this.FM_Api.connect('nfctypes').query({ page: 1, sort: 'name' }, (data) => {
					this.nfcTypesData = data;
					resolve(this.nfcTypesData);
				});
			}
		});
	}

	brandingOptions(): ListData[] {
		if (this.global.config.isDebug) {
			return [
				{
					id: 'footmarks',
					name: 'FOOTMARKS',
				},
				{
					id: 'footmarks-dark',
					name: 'FOOTMARKS_DARK',
				},
				{
					id: 'footmarks-new',
					name: 'FOOTMARKS_NEW',
				},
				{
					id: 'atrius',
					name: 'ATRIUS',
				},
				{
					id: 'extreme',
					name: 'EXTREME',
				},
				{
					id: 'tile',
					name: 'TILE',
				},
			];
		} else {
			return [
				{
					id: 'footmarks',
					name: 'FOOTMARKS',
				},
				{
					id: 'atrius',
					name: 'ATRIUS',
				},
				{
					id: 'extreme',
					name: 'EXTREME',
				},
				{
					id: 'tile',
					name: 'TILE',
				},
			];
		}
	}

	countries(val: string): Observable<any> {
		const base = this.global.config.endpoints.api;

		const cond = JSON.stringify({
			name: {
				$regex: '^' + val,
				$options: 'i',
			},
		});

		const params = new HttpParams();
		params.set('conditions', cond);

		return this.http.get(base + 'countries', { params: params }).pipe(
			map((response: any) => {
				return response.map((o) => o.name);
			})
		);
	}

	privateRegions(): ListData[] {
		return [
			{
				id: 'F2356731-FBD4-4A10-8AA2-C89ADF48A98D',
				name: 'Region 1',
			},
			{
				id: '784F6848-5209-4EE9-A461-5B44F704909D',
				name: 'Region 2',
			},
			{
				id: '64BD4037-F3DB-45BE-8489-944F3F1F7198',
				name: 'Region 3',
			},
			{
				id: '7BC38197-FB4C-4C9E-994E-F4EE5475354E',
				name: 'Region 4',
			},
			{
				id: 'DE1367C7-2286-42BA-BB5B-775AC340CBF8',
				name: 'Region 5',
			},
			{
				id: 'C9545936-E5F8-475F-886C-C9B9E9978CCB',
				name: 'Region 6',
			},
			{
				id: '857B9EE9-FF68-41E3-8C14-90814ADAF731',
				name: 'Region 7',
			},
			{
				id: '97A4725D-7F4E-453B-B3EA-8AC89AAEFB81',
				name: 'Region 8',
			},
			{
				id: 'B300AD98-A2B9-4DF4-AF48-E3F04B9454FF',
				name: 'Region 9',
			},
			{
				id: '18C26735-44EE-4252-BDFA-00E640A54162',
				name: 'Region 10',
			},
			{
				id: '991C0A44-6603-4BFF-A797-4C5E70186DF8',
				name: 'Region 11',
			},
			{
				id: '4E9D0E97-73CC-4ACB-A89C-6C4A39D95D2D',
				name: 'Region 12',
			},
		];
	}

	dataTypes(): DataType[] {
		return [
			DataType.BOOLEAN,
			DataType.CURRENCY,
			DataType.DATETIME,
			DataType.NUMBER,
			DataType.STRING,
			DataType.LIST,
		];
		/*
		return Object.keys(DataType).map((key) => {
			return DataType[key];
		});
		*/
	}

	broadcastModes(): string[] {
		return ['open', 'private'];
	}
	broadcastTypes(): string[] {
		return ['secure', 'ibeacon', 'eddystone', 'physicalWeb', 'eddystoneService'];
	}

	maxAllowedIntervals(): ListData[] {
		return [
			{
				id: 'none',
				name: 'TOTAL',
			},
			{
				id: 'hour',
				name: 'PER_HOUR',
			},
			{
				id: 'day',
				name: 'PER_DAY',
			},
			{
				id: 'week',
				name: 'PER_WEEK',
			},
			{
				id: 'month',
				name: 'PER_MONTH',
			},
		];
	}

	getPayloadDisplay(): string[] {
		return ['fullscreen', 'large', 'small'];
	}

	getPayloadProviders(): string[] {
		return ['youtube', 'vimeo', 'custom'];
	}

	getPayloadTypes(): ListData[] {
		return [
			{ id: 'passive', name: 'PASSIVE' },
			{ id: 'video', name: 'VIDEO' },
			{ id: 'image', name: 'IMAGE' },
			{ id: 'alert', name: 'ALERT' },
			{ id: 'html', name: 'HTML' },
			{ id: 'api', name: 'API' },
			{ id: 'url', name: 'URL' },
			{ id: 'custom', name: 'CUSTOM' },
		];
	}

	getPayloadActions(): ListData[] {
		return [
			{ id: 'passive', name: 'PASSIVE' },
			{ id: 'autoShow', name: 'AUTOSHOW' },
			{ id: 'prompt', name: 'PROMPT' },
		];
	}

	getStates(): Observable<any> {
		return this.http.get('/assets/json/states.json').pipe(shareReplay(1));
	}

	getStateLabels(): Observable<any> {
		return this.http.get('/assets/json/states.json').pipe(
			shareReplay(1),
			map((response: any) => {
				return response.map((o) => o.label);
			})
		);
	}

	getStateOptions(val: string): Promise<any> {
		return new Promise((resolve) => {
			const params: SearchParams = {
				page: 1,
				per_page: 30,
				sort: 'name',
				select: 'state',
			};

			if (val) {
				params.conditions = {
					state: { $regex: '\\b' + val, $options: 'i' },
				};
			}

			this.FM_Api.connect('sites').query(params, (res) => {
				let states = res.map((o) => o.state);
				states = uniq(states);
				states = sortBy(states);
				resolve(states);
			});
		});
	}

	getTagOptions(objtype: ProfileType, val: string): Promise<any> {
		return new Promise((resolve) => {
			const params: SearchParams = {
				page: 1,
				per_page: 30,
				sort: 'name',
				select: 'tags',
			};

			if (val) {
				params.conditions = JSON.stringify({
					tags: { $regex: '\\b' + val, $options: 'i' },
				});
			}

			const endpoint = this.FM_Utils.getNamePluralized(objtype);

			this.FM_Api.connect(endpoint).query(params, (data) => {
				let tags = [];
				data.forEach((item) => {
					if (item.tags) {
						tags = tags.concat(item.tags);
					}
				});

				tags = uniq(tags);
				tags = sortBy(tags);

				// FILTER THE RESULTS
				tags = tags.filter((t) => t.includes(val));

				resolve(tags);
			});
		});
	}

	getTimezones(): Observable<any> {
		return this.http.get('/assets/json/timezones.json').pipe(shareReplay(1));
	}

	typeInfo(objtype: ProfileType): TypeInfo {
		const o = {
			adminuser: {
				endpoint: 'adminusers',
				icon: 'fa fa-users',
				key: 'ALL_ADMINS',
				message: 'NO_ADMINS',
				delete_message: 'DELETED_ADMIN',
				state: 'adminusers',
			},
			app: {
				endpoint: 'apps',
				icon: 'fa fa-tablet-alt',
				key: 'ALL_APPS',
				message: 'NO_APPS',
				delete_message: 'DELETED_APP',
				state: 'apps',
			},
			asset: {
				endpoint: 'assets',
				icon: 'fa fa-asterisk',
				key: 'ALL_ASSETS',
				message: 'NO_ASSETS',
				delete_message: 'DELETED_ASSET',
				state: 'assets',
			},
			beacon: {
				endpoint: 'beacons',
				icon: 'fab fa-bluetooth-b',
				key: 'ALL_BEACONS',
				message: 'NO_BEACONS',
				delete_message: 'DELETED_BEACON',
				state: 'beacons',
			},
			company: {
				endpoint: 'companies',
				icon: 'fa fa-building',
				key: 'ALL_COMPANIES',
				message: 'NO_COMPANIES',
				delete_message: 'DELETED_COMPANY',
				state: 'companies',
			},
			device: {
				endpoint: 'devices',
				icon: 'fa-solid fa-mobile-alt',
				key: 'ALL_DEVICES',
				message: 'NO_DEVICES',
				delete_message: 'DELETED_DEVICE',
				state: 'devices',
			},
			district: {
				endpoint: 'districts',
				icon: 'fa fa-th',
				key: 'ALL_DISTRICTS',
				message: 'NO_DISTRICTS',
				delete_message: 'DELETED_DISTRICT',
				state: 'districts',
			},
			division: {
				endpoint: 'districts',
				icon: 'fa fa-th',
				key: 'ALL_DIVISIONS',
				message: 'NO_DIVISIONS',
				delete_message: 'DELETED_DIVISION',
				state: 'divisions',
			},
			experience: {
				endpoint: 'experiences',
				icon: 'fa fa-cube',
				key: 'ALL_EXPERIENCES',
				message: 'NO_EXPERIENCES',
				delete_message: 'DELETED_EXPERIENCE',
				state: 'experiences',
			},
			gateway: {
				endpoint: 'gateways',
				icon: 'fa-solid fa-bezier-curve',
				key: 'ALL_GATEWAYS',
				message: 'NO_GATEWAYS',
				delete_message: 'DELETED_GATEWAY',
				state: 'gateways',
			},
			geofence: {
				endpoint: 'geofences',
				icon: 'fa-solid fa-dot-circle',
				key: 'ALL_GEOFENCES',
				message: 'NO_GEOFENCES',
				delete_message: 'DELETED_GEOFENCE',
				state: 'geofences',
			},
			journey: {
				endpoint: 'journeys',
				icon: 'fa fa-cubes',
				key: 'ALL_JOURNEYS',
				message: 'NO_JOURNEYS',
				delete_message: 'DELETED_JOURNEY',
				state: 'journeys',
			},
			nfc: {
				endpoint: 'nfcs',
				icon: 'fa-solid fa-qrcode',
				key: 'ALL_NFCS',
				message: 'NO_NFCS',
				delete_message: 'DELETED_NFC',
				state: 'nfcs',
			},
			partner: {
				endpoint: 'partners',
				icon: 'fa-solid fa-handshake',
				key: 'ALL_PARTNERS',
				message: 'NO_PARTNERS',
				delete_message: 'DELETED_PARTNER',
				state: 'partners',
			},
			payload: {
				endpoint: 'payloads',
				icon: 'fa fa-file-image',
				key: 'ALL_PAYLOADS',
				message: 'NO_PAYLOADS',
				delete_message: 'DELETED_PAYLOAD',
				state: 'payloads',
			},
			platformtask: {
				endpoint: 'platformtasks',
				icon: 'fa-solid fa-tasks',
				key: 'ALL_PLATFORMTASKS',
				message: 'NO_PLATFORMTASKS',
				delete_message: 'DELETED_PLATFORMTASKS',
				state: 'platformtasks',
			},
			program: {
				endpoint: 'programs',
				icon: 'fa-solid fa-diagram-subtask',
				key: 'ALL_PROGRAMS',
				message: 'NO_PROGRAMS',
				delete_message: 'DELETED_PROGRAMS',
				state: 'programs',
			},
			push: {
				endpoint: 'pushes',
				icon: 'fa-solid fa-expand-arrows-alt',
				key: 'ALL_PUSHES',
				message: 'NO_PUSHES',
				delete_message: 'DELETED_PUSH',
				state: 'pushes',
			},
			region: {
				endpoint: 'regions',
				icon: 'fa fa-th',
				key: 'ALL_REGIONS',
				message: 'NO_REGIONS',
				delete_message: 'DELETED_REGION',
				state: 'regions',
			},
			site: {
				endpoint: 'sites',
				icon: 'fa fa-map-marker-alt',
				key: 'ALL_SITES',
				message: 'NO_SITES',
				delete_message: 'DELETED_SITE',
				state: 'sites',
			},
			supplier: {
				endpoint: 'suppliers',
				icon: 'fa fa-th',
				key: 'ALL_SUPPLIERS',
				message: 'NO_SUPPLIERS',
				delete_message: 'DELETED_SUPPLIER',
				state: 'suppliers',
			},
			task: {
				endpoint: 'tasks',
				icon: 'fa-solid fa-tasks',
				key: 'ALL_TASKS',
				message: 'NO_TASKS',
				delete_message: 'DELETED_TASK',
				state: 'tasks',
			},
			zone: {
				endpoint: 'zones',
				icon: 'fa fa-th-large',
				key: 'ALL_ZONES',
				message: 'NO_ZONES',
				delete_message: 'DELETED_ZONE',
				state: 'zones',
			},
		};

		return o[objtype];
	}
}
