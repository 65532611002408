<div class="filter-wrap">
	@if (isLoading) {
		<div class="filter-loader">
			<span class="fa fa-circle-notch fa-spin"></span>
		</div>
	}

	@if (!isLoading) {
		<ul class="filter-options" *ngIf="search.total === 0">
			<li class="empty-list">No Items Available</li>
		</ul>

		<ul class="filter-options" *ngIf="search.total <= SEARCH_LIMIT">
			<li
				class="fm-savedfilter"
				*ngFor="let filter of search.list; trackBy: 'id' | trackByProperty"
				(click)="filterClick(filter)"
				[ngClass]="{ active: isActive(filter) }">
				<a [routerLink]="">
					<span class="filter-status"><i class="fa fa-check"></i></span>
					<span class="filter-title" translate="{{ filter.name }}"></span>
				</a>
			</li>
		</ul>

		<div class="typeahead" *ngIf="search.total > SEARCH_LIMIT">
			<div class="field-editor">
				<input
					type="text"
					#searchInput
					class="fm-input"
					placeholder="{{ 'SEARCH' | translate }}"
					matInput
					[formControl]="myControl"
					[matAutocomplete]="auto" />

				<div class="input-loader" *ngIf="isSearching">
					<span class="fa fa-circle-notch fa-spin"></span>
				</div>
				<mat-autocomplete #auto="matAutocomplete" (optionSelected)="setItem($event)">
					<mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{
						option.name | translate
					}}</mat-option>
				</mat-autocomplete>
			</div>

			<ul class="filter-list" *ngIf="selected.length">
				<li class="fm-tag" *ngFor="let item of selected">
					<span [translate]="item.name"></span>
					<div class="remove-tag" (click)="clearItem(item)">
						<i class="fa fa-times"></i>
					</div>
				</li>
			</ul>
		</div>
	}
</div>
