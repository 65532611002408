@if (recentCompanies?.length) {
	<div class="company-quickswitch">
		<ul>
			<li *ngFor="let c of recentCompanies" (click)="selectItem(c)">
				<div class="list-cell wrapped">
					<profile-image
						[objtype]="ProfileType.COMPANY"
						[obj]="c"
						mode="size_50"></profile-image>
				</div>
				<div class="name">{{ c.name }}</div>
			</li>
		</ul>
	</div>
}
