<header class="site-header" [ngClass]="{ inactive: hasMessage }">
	<div class="site-header-inner">
		<div class="fm-nav-header" (click)="view.showMainMenu = !view.showMainMenu">
			<a class="fm-nav-toggle">
				<i *ngIf="!view.showMainMenu" class="fa-solid fa-bars"></i>
				<i *ngIf="view.showMainMenu" class="fa-solid fa-times"></i>
			</a>
		</div>

		<div class="brand-icon"></div>

		<h1 [translate]="global.pageTitle"></h1>

		<section class="global-controls">
			<ng-container *fmPermission="{ key: 'notification', value: 'view' }">
				<control-panel [active]="currentPanel" panel="notifications">
					<button type="button" class="fm-dropdown" (click)="setPanel('notifications')">
						@if (global.notifications && global.notifications.isLoading) {
							<div class="global-icon">
								<i class="fa fa-circle-notch fa-spin"></i>
							</div>
						}
						@if (!global.notifications || !global.notifications.isLoading) {
							<div class="global-icon">
								<i class="fa fa-bell" *ngIf="!global.notifications.unreadCount"></i>
								<span
									class="notification-count"
									*ngIf="global.notifications.unreadCount"
									[innerText]="global.notifications.unreadCount"></span>
							</div>
						}
						<div class="global-name" translate="NOTIFICATIONS"></div>
					</button>

					<div
						[controlPanel]="currentPanel"
						(controlPanelEvent)="updatePanel($event)"
						class="control-panel notifications-panel center"
						*ngIf="currentPanel === 'notifications'">
						<div class="arrow-up"></div>
						<ul>
							<li class="control-section">
								<h4 class="dropdown-title" translate="NOTIFICATIONS"></h4>
								<div
									class="global-icon"
									*ngIf="global.notifications && global.notifications.isLoading">
									<i class="fa fa-circle-notch fa-spin"></i>
								</div>
								<div
									*ngIf="
										!global.notifications.isLoading &&
										!global.notifications.list.length
									"
									class="fm-message">
									<i class="fa fa-thumbs-up"></i>
									<div translate="NO_NOTIFICATIONS"></div>
								</div>
								@if (global.notifications.list.length) {
									<div class="notification-actions">
										<a
											[routerLink]=""
											(click)="deleteAllNotifications()"
											translate="CLEAR_ALL"
											stop-propagation></a>
									</div>
								}

								@if (
									!global.notifications.isLoading &&
									global.notifications.list.length
								) {
									<div class="notification-list">
										<a
											class="notification"
											*ngFor="let notif of global.notifications.list"
											(click)="viewNotifications(notif)">
											<div class="list-cell fm-list-detail">
												<div class="detail-main">
													<span
														class="notification-unread"
														*ngIf="!notif.isRead"></span>
													<span
														class="notification-message"
														[translate]="
															notif.content | limitString: 60
														"></span>
													<ul class="notification-info detail">
														<li
															[innerText]="
																notif.created | customDate
															"></li>
														<li [innerText]="notif.notifType"></li>
														<li></li>
													</ul>
												</div>
												<span
													class="list-action notification-severity"
													[ngClass]="notif.severity"
													[innerText]="notif.severity"></span>
												<span
													class="list-action notification-remove"
													(click)="clearNotification(notif)"
													stop-propagation>
													<i class="fa fa-times"></i>
												</span>
											</div>
										</a>
									</div>
								}

								@if (global.notifications.list.length) {
									<div class="notification-list-count">
										{{ global.notifications.list.length }} notifications
									</div>
								}
							</li>

							@if (global.config.isDebug) {
								<li class="control-section">
									<a
										[routerLink]=""
										class="fm-button"
										translate="VIEW_ALL"
										(click)="viewNotifications()"
										stop-propagation></a>
									<a
										[routerLink]=""
										class="fm-button"
										translate="ADD_NOTIFICATION"
										(click)="addNotification()"
										stop-propagation></a>
									<!--<a  class="fm-button" translate="MANAGE_NOTIFICATIONS" (click)="manageNotifications()"></a>-->
								</li>
							}
						</ul>
					</div>
				</control-panel>
			</ng-container>

			<control-panel [active]="currentPanel" panel="user">
				<button type="button" class="fm-dropdown" (click)="setPanel('user')">
					<div class="global-icon">
						<profile-image
							mode="dark"
							[objtype]="ProfileType.ADMINUSER"
							[obj]="global.user"></profile-image>
						<!--<i class="fa fa-user"></i>-->
					</div>
					<div class="global-name">
						{{ global.user.name | slice: 0 : 20 }}
						{{ global.user.name.length > 20 ? '...' : '' }}
					</div>
				</button>

				@if (currentPanel === 'user') {
					<div
						[controlPanel]="currentPanel"
						(controlPanelEvent)="updatePanel($event)"
						class="control-panel center">
						<div class="arrow-up"></div>
						<ul>
							<li class="control-section">
								<profile-image
									mode="dark"
									[objtype]="ProfileType.ADMINUSER"
									[obj]="global.user"></profile-image>
								<h4 [innerText]="global.user.name"></h4>
								<div class="note" [innerText]="global.user.id"></div>
							</li>

							@if (global.user?.showSuper) {
								<li class="control-section">
									<div class="control-row">
										<label class="label">Is Super</label>
										<div class="field-toggle-wrap">
											<div
												class="fm-toggle"
												[ngClass]="{ active: global.user.isSuper }"
												(click)="updateSuper()">
												<div class="fm-toggle-bg">
													<div class="fm-toggle-out"></div>
													<div class="fm-toggle-in"></div>
													<div class="fm-toggle-switch"></div>
												</div>
											</div>
										</div>
									</div>
									<div class="control-row">
										<label class="label" translate="IS_DEV"></label>
										<div class="field-toggle-wrap">
											<div
												class="fm-toggle"
												[ngClass]="{ active: global.user.isDev }"
												(click)="global.user.isDev = !global.user.isDev">
												<div class="fm-toggle-bg">
													<div class="fm-toggle-out"></div>
													<div class="fm-toggle-in"></div>
													<div class="fm-toggle-switch"></div>
												</div>
											</div>
										</div>
									</div>
									<div class="control-row">
										<label class="label">Debug Mode</label>
										<div class="field-toggle-wrap">
											<div
												class="fm-toggle"
												[ngClass]="{ active: global.config.isDebug }"
												(click)="
													global.config.isDebug = !global.config.isDebug
												">
												<div class="fm-toggle-bg">
													<div class="fm-toggle-out"></div>
													<div class="fm-toggle-in"></div>
													<div class="fm-toggle-switch"></div>
												</div>
											</div>
										</div>
									</div>
									@if (global.config.isDebug) {
										<select
											class="fm-select"
											name="admingroup"
											[(ngModel)]="global.group"
											(change)="setPermissions()">
											<option
												[value]="undefined"
												translate="SELECT_GROUP"></option>
											<option
												[value]="g"
												[translate]="g.name"
												*ngFor="let g of groups"></option>
										</select>
									}
								</li>
							}
							<li class="control-section">
								<ng-container *fmPermission="{ key: 'adminuser', value: 'update' }">
									<a
										*ngIf="global.company.id === global.user.company"
										[routerLink]="['adminusers', global.user.id]"
										class="fm-button"
										translate="VIEW_ACCOUNT"></a>
								</ng-container>
								<a
									[routerLink]=""
									class="fm-button"
									(click)="loadAdminProfile()"
									translate="EDIT_ACCOUNT"></a>
							</li>
							<li class="control-section">
								<a
									[routerLink]=""
									class="fm-button"
									(click)="logout()"
									translate="LOGOUT"></a>
							</li>
						</ul>
					</div>
				}
			</control-panel>

			<!-- COMPANY -->
			<control-panel [active]="currentPanel" panel="company'">
				<button type="button" class="fm-dropdown" (click)="setPanel('company')">
					<div class="global-icon">
						<profile-image
							mode="dark"
							[objtype]="ProfileType.COMPANY"
							[obj]="global.company"></profile-image>
					</div>
					<div class="global-name">
						{{ global.company.name | slice: 0 : 20
						}}{{ global.company.name.length > 20 ? '...' : '' }}
					</div>
				</button>

				@if (currentPanel === 'company') {
					<div
						[controlPanel]="currentPanel"
						(controlPanelEvent)="updatePanel($event)"
						class="control-panel center">
						<div class="arrow-up"></div>
						<ul>
							<li class="control-section">
								<profile-image
									mode="dark"
									[objtype]="ProfileType.COMPANY"
									[obj]="global.company"></profile-image>
								<h4 [innerText]="global.company.name"></h4>
								<div class="note" [innerText]="global.company.id"></div>
							</li>
							@if (global.config.isDebug) {
								<li class="control-section">
									<h4 class="dropdown-title" translate="BRANDING"></h4>
									<select
										class="fm-select"
										name="branding"
										[(ngModel)]="global.branding"
										(ngModelChange)="updateBranding()">
										<option
											[value]="type.id"
											*ngFor="let type of brandingOptions"
											[translate]="type.name"></option>
									</select>
								</li>
							}
							<li class="control-section" *ngIf="global.config.isDebug">
								<h4 class="dropdown-title" translate="SETTINGS"></h4>
								<div class="control-row">
									<label class="label" translate="SMART_CONNECT"></label>
									<div class="field-toggle-wrap">
										<div
											class="fm-toggle"
											[ngClass]="{ active: getSetting('smart-connect') }"
											(click)="toggleSetting('smart-connect')">
											<div class="fm-toggle-bg">
												<div class="fm-toggle-out"></div>
												<div class="fm-toggle-in"></div>
												<div class="fm-toggle-switch"></div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-row">
									<label class="label" translate="SMART_TRACKING"></label>
									<div class="field-toggle-wrap">
										<div
											class="fm-toggle"
											[ngClass]="{ active: getSetting('asset-tracking') }"
											(click)="toggleSetting('asset-tracking')">
											<div class="fm-toggle-bg">
												<div class="fm-toggle-out"></div>
												<div class="fm-toggle-in"></div>
												<div class="fm-toggle-switch"></div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-row">
									<label class="label" translate="SMART_ASSOCIATE"></label>
									<div class="field-toggle-wrap">
										<div
											class="fm-toggle"
											[ngClass]="{ active: getSetting('smart-associate') }"
											(click)="toggleSetting('smart-associate')">
											<div class="fm-toggle-bg">
												<div class="fm-toggle-out"></div>
												<div class="fm-toggle-in"></div>
												<div class="fm-toggle-switch"></div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-row">
									<label class="label" translate="GEOFENCE"></label>
									<div class="field-toggle-wrap">
										<div
											class="fm-toggle"
											[ngClass]="{ active: getSetting('fm-geofence-active') }"
											(click)="toggleSetting('fm-geofence-active')">
											<div class="fm-toggle-bg">
												<div class="fm-toggle-out"></div>
												<div class="fm-toggle-in"></div>
												<div class="fm-toggle-switch"></div>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li
								class="control-section"
								*fmPermission="{ key: 'company', value: 'read' }">
								<a
									[routerLink]="['companies', global.company.id]"
									class="fm-button"
									translate="VIEW_COMPANY"></a>
								<a
									[routerLink]=""
									class="fm-button"
									(click)="loadCompanyModal()"
									translate="CHANGE_COMPANY"></a>
								<a
									[routerLink]=""
									*ngIf="global.company.id !== global.user.company"
									class="fm-button"
									(click)="returnToRoot()"
									translate="RETURN_TO_ROOT"></a>
							</li>
						</ul>
					</div>
				}
			</control-panel>

			<!-- SETTINGS -->
			<control-panel [active]="currentPanel" panel="settings">
				<button type="button" class="fm-dropdown" (click)="currentPanel = 'settings'">
					<div class="global-icon">
						<i class="fa fa-cog"></i>
					</div>
					<div class="global-name" translate="SETTINGS"></div>
				</button>

				@if (currentPanel === 'settings') {
					<div
						[controlPanel]="currentPanel"
						(controlPanelEvent)="updatePanel($event)"
						class="control-panel">
						<div class="arrow-up"></div>
						<ul>
							<li class="control-section">
								<h4 class="dropdown-title" translate="ENVIRONMENT"></h4>
								<div
									class="note"
									translate="{{ getEnvironment(global.config.current) }}"></div>
								<div class="dev-tools" *ngIf="global.config.isDebug">
									<a
										href="{{ global.config.endpoints.api }}/docs/?auth={{
											global.config.token
										}}"
										target="_blank"
										translate="DEV_DOCS"></a>
									<a [routerLink]="" (click)="smsModal()">Send SMS</a>
									<a [routerLink]="" (click)="logGlobal()">Out Global</a>
									<a [routerLink]="" (click)="clearUserSettings()"
										>Delete User Settings</a
									>
									<a [routerLink]="" (click)="batchAdd()">Batch Add</a>
									<!--
								<a (click)="deleteAll('payloads')">Delete All Payloads</a>
                                <a (click)="deleteAll('sites')">Delete All Sites</a>
                                <a (click)="deleteAll('zones')">Delete All Zones</a>
                                --></div>
								<div class="dev-tools">
									<a (click)="openNews()"
										><span translate="VERSION"></span>
										{{ global.config.version }}</a
									>
								</div>
							</li>

							<li class="control-section">
								<h4 class="dropdown-title" translate="DOCUMENTATION"></h4>
								<img
									src="https://developer.footmarks.com/password"
									style="display: none" />
								<div class="help-link">
									<button
										class="fm-button"
										[routerLink]=""
										(click)="openHelp('/docs/supported-functionality')"
										translate="API_DOCS"></button>
								</div>
								<div class="help-link">
									<button
										class="fm-button"
										[routerLink]=""
										(click)="openHelp('/docs')"
										translate="HELP_DOCS"></button>
								</div>
								<a
									class="fm-button"
									[routerLink]="['companies', global.company.id, 'apps']"
									translate="SDK_DOWNLOAD"></a>
							</li>
							<li class="control-section" *ngIf="global.user.isDev">
								<h4 class="dropdown-title" translate="LANGUAGE"></h4>
								<ul class="sub-menu">
									<li
										[ngClass]="global.language.key === lang.key ? 'active' : ''"
										(click)="setLanguage(lang)"
										*ngFor="let lang of languages">
										{{ lang.name }}
									</li>
								</ul>
							</li>
							<li class="control-section">
								<h4 class="dropdown-title" translate="TOOLS"></h4>
								<a
									class="fm-button"
									[routerLink]=""
									(click)="launchWizard()"
									translate="LAUNCH_WIZARD"></a>
								<!--
								<a
								*ngIf="global.user.isSuper"
								[routerLink]=""
								class="fm-button"
								(click)="deviceAuditing()"
								translate="DEVICE_AUDITING"></a>
							-->
							</li>
							<li class="control-section">
								<div class="help-link">
									<button
										class="fm-button"
										[routerLink]=""
										(click)="featureRequest()"
										translate="FEATURE_REQUEST"></button>
								</div>
							</li>
						</ul>
					</div>
				}
			</control-panel>
		</section>
	</div>
</header>
