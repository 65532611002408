<div class="filter-wrap">
	@if (open) {
		<div class="typeahead">
			<input
				type="text"
				#searchInput
				class="fm-input"
				placeholder="{{ 'SEARCH' | translate }}"
				matInput
				[formControl]="myControl"
				[matAutocomplete]="auto" />
			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="setItem($event)">
				<mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{
					option
				}}</mat-option>
			</mat-autocomplete>
		</div>
	}
	@if (list.length) {
		<ul class="filter-list">
			<li class="fm-tag" *ngFor="let item of list">
				<span [innerText]="item"></span>
				<div class="remove-tag" (click)="clearItem(item)"><i class="fa fa-times"></i></div>
			</li>
		</ul>
	}
</div>
