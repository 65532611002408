<section class="fm-sidebar fm-filterlist" [ngClass]="{ 'hide-actions': settings.hideActions }">
	@if (view.showSavedFilters) {
		<div class="filter-actions">
			<a [routerLink]="" (click)="cancelFilter()" translate="CANCEL"></a>
		</div>

		<div class="fm-filterlist-wrap">
			<div class="mini-loader" *ngIf="view.loadingFilters">
				<span class="fa fa-circle-notch fa-spin"></span>
			</div>
			<div class="fm-list-inner" *ngIf="!view.loadingFilters">
				<div class="filter-section">
					<h4 translate="BASIC_FILTERS"></h4>
					<ul>
						<li
							class="fm-savedfilter"
							*ngFor="let filter of standardFilters"
							(click)="loadFilter(filter)"
							[ngClass]="{ active: isActive(filter) }">
							<a [routerLink]="">
								<span class="filter-status"><i class="fa fa-check"></i></span>
								{{ filter.name }}
							</a>
						</li>
					</ul>
				</div>

				<div class="filter-section" *ngIf="savedFilters.length">
					<h4 translate="CUSTOM_FILTERS"></h4>
					<ul>
						<li
							class="fm-savedfilter"
							*ngFor="let filter of savedFilters"
							(click)="loadFilter(filter)"
							[ngClass]="{ active: isActive(filter) }">
							<a [routerLink]="" [innerText]="filter.name"></a>
							<span class="savedfilter-actions">
								<span
									*ngIf="!filter.isRemoving"
									class="filter-remove"
									(click)="removeFilter(filter)"
									stop-propagation
									><i class="fa fa-remove"></i
								></span>
								<span *ngIf="filter.isRemoving"
									><i class="fa fa-circle-notch fa-spin"></i
								></span>
							</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	}

	@if (!view.showSavedFilters) {
		<div class="filter-actions">
			<a [routerLink]="" (click)="showSavedFilters()" translate="LOAD"></a>
			<a [routerLink]="" (click)="clearFilter()" translate="CLEAR"></a>
		</div>

		<div class="fm-filterlist-wrap">
			<div class="fm-list-inner">
				<div class="filter-group">
					<!-- ADMINUSER FILTERS -->
					@if (isType(ProfileType.ADMINUSER)) {
						<!--
						<div class="super-filters" *ngIf="global.user.isSuper">
							<div class="fm-filter">
								<h3 translate="SEARCH_ALL_COMPANIES"></h3>
								<fm-toggle [(ngModel)]="filters.jedi"></fm-toggle>
							</div>
						</div>
						-->
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.groups }">
							<h3 translate="FILTER_BY_GROUP"></h3>
							<filter-search
								#filter
								[(filter)]="filters.groups"
								[searchtype]="ProfileType.GROUP"
								placeholder="GROUPS"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.require2FA }">
							<h3 translate="FILTER_BY_SETTINGS"></h3>
							<filter-settings
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-settings>
						</fm-filter>

						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
					}

					<!-- APP FILTERS -->
					@if (isType(ProfileType.APP)) {
						<fm-filter class="fm-filter fixed" *ngIf="partnerList.length">
							<filter-partners
								#filter
								[partners]="partnerList"
								[(filter)]="filters.partners"
								(update)="runupdate()"></filter-partners>
						</fm-filter>
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								[objtype]="objtype"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								type="apps"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					}

					<!-- ASSET FILTERS -->
					<ng-container *ngIf="isType(ProfileType.ASSET)">
						<fm-filter class="fm-filter fixed" *ngIf="partnerList.length">
							<filter-partners
								#filter
								[partners]="partnerList"
								[(filter)]="filters.partners"
								(update)="runupdate()"></filter-partners>
						</fm-filter>
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								[objtype]="objtype"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.assetType }">
							<h3 translate="FILTER_BY_TYPE"></h3>
							<filter-type
								#filter
								[(filter)]="filters.assetType"
								typelist="assetTypes"
								(update)="runupdate()"></filter-type>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.suppliers }">
							<h3 translate="FILTER_BY_SUPPLIER"></h3>
							<filter-search
								#filter
								[(filter)]="filters.suppliers"
								[searchtype]="ProfileType.SUPPLIER"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.division }">
							<h3 translate="FILTER_BY_DIVISION"></h3>
							<filter-search
								#filter
								[(filter)]="filters.division"
								[searchtype]="ProfileType.DIVISION"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								[objtype]="objtype"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								[objtype]="objtype"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>

					<!-- BEACON FILTERS -->
					<ng-container *ngIf="isType(ProfileType.BEACON)">
						<!--
					<fm-filter class="fm-filter super-filters" *ngIf="global.user.isSuper">
						<h3 translate="SEARCH_ALL_COMPANIES"></h3>
						<div class="filter-wrap">
							<filter-toggle [(ngModel)]="filters.jedi"></filter-toggle>
						</div>
					</fm-filter>
					-->
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter
							class="fm-filter"
							[ngClass]="{ active: filters.asset }"
							*fmSetting="'asset-tracking'">
							<h3 translate="FILTER_BY_ASSET"></h3>
							<filter-search
								#filter
								[(filter)]="filters.asset"
								[searchtype]="ProfileType.ASSET"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.assetId }">
							<h3 translate="FILTER_BY_ASSET_ID"></h3>
							<filter-input
								#filter
								[(filter)]="filters.assetId"
								(update)="runupdate()"
								type="number"
								placeholder="ASSET_ID"></filter-input>
						</fm-filter>
						<fm-filter
							class="fm-filter"
							[ngClass]="{ active: filters.ranges }"
							*fmSetting="'asset-tracking'">
							<h3 translate="FILTER_BY_RANGE"></h3>
							<filter-range
								#filter
								[(filter)]="filters.ranges"
								(update)="runupdate()"></filter-range>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.site }">
							<h3 translate="FILTER_BY_SITE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.site"
								[searchtype]="ProfileType.SITE"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter
							class="fm-filter"
							[ngClass]="{ active: filters.zone }"
							*fmSetting="'smart-connect'">
							<h3 translate="FILTER_BY_ZONE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.zone"
								[searchtype]="ProfileType.ZONE"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.division }">
							<h3 translate="FILTER_BY_DIVISION"></h3>
							<filter-search
								#filter
								[(filter)]="filters.division"
								[searchtype]="ProfileType.DIVISION"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.geocoordinates }">
							<h3 translate="FILTER_BY_GEOCOORDINATES"></h3>
							<filter-geocoordinates
								#filter
								[(filter)]="filters.geocoordinates"
								(update)="runupdate()"></filter-geocoordinates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.battery }">
							<h3 translate="FILTER_BY_BATTERY"></h3>
							<filter-input
								#filter
								[(filter)]="filters.battery"
								(update)="runupdate()"
								type="number"
								placeholder="PERCENT"></filter-input>
						</fm-filter>
						<fm-filter
							class="fm-filter"
							[ngClass]="{ active: filters.advertisementInterval }">
							<h3 translate="FILTER_BY_INTERVAL"></h3>
							<filter-input
								#filter
								[(filter)]="filters.advertisementInterval"
								(update)="runupdate()"
								type="number"
								placeholder="INTERVAL"></filter-input>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.txPower }">
							<h3 translate="FILTER_BY_TX_POWER"></h3>
							<filter-select
								#filter
								[(filter)]="filters.txPower"
								(update)="runupdate()"
								[options]="beaconLevels"></filter-select>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.beaconType }">
							<h3 translate="FILTER_BY_TYPE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.beaconType"
								api="beacontypes"
								prop="beaconType"
								[limit]="10"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.attributes }">
							<h3 translate="FILTER_BY_ATTRIBUTE"></h3>
							<filter-attributes
								#filter
								[(filter)]="filters.attributes"
								(update)="runupdate()"
								type="beacon"></filter-attributes>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"
								[objtype]="objtype"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								[objtype]="objtype"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>

					<!-- COMPANY FILTERS -->
					<ng-container *ngIf="isType(ProfileType.COMPANY)">
						<fm-filter class="fm-filter super-filters" *ngIf="global.config.isDebug">
							<h3 translate="SEARCH_ALL_COMPANIES"></h3>
							<filter-toggle
								#filter
								[(filter)]="filters.jedi"
								(update)="runupdate()"></filter-toggle>
						</fm-filter>
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								[objtype]="objtype"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
					</ng-container>

					<!-- DEVICE FILTERS -->
					<ng-container *ngIf="isType(ProfileType.DEVICE)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								[objtype]="objtype"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>

					<!-- EXPERIENCE FILTERS -->
					<ng-container *ngIf="isType(ProfileType.EXPERIENCE)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.apps }">
							<h3 translate="FILTER_BY_APPS"></h3>
							<filter-search
								#filter
								[(filter)]="filters.apps"
								[searchtype]="ProfileType.APP"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.payload }">
							<h3 translate="FILTER_BY_PAYLOAD"></h3>
							<filter-search
								#filter
								[(filter)]="filters.payload"
								[searchtype]="ProfileType.PAYLOAD"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								[objtype]="objtype"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>

					<!-- GATEWAY FILTERS -->
					<ng-container *ngIf="isType(ProfileType.GATEWAY)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								[objtype]="objtype"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.site }">
							<h3 translate="FILTER_BY_SITE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.site"
								[searchtype]="ProfileType.SITE"
								placeholder="SITE"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter
							class="fm-filter"
							[ngClass]="{ active: filters.zone }"
							*fmSetting="'smart-connect'">
							<h3 translate="FILTER_BY_ZONE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.zone"
								[searchtype]="ProfileType.ZONE"
								placeholder="ZONES"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.gatewayType }">
							<h3 translate="FILTER_BY_TYPE"></h3>
							<filter-type
								#filter
								[(filter)]="filters.gatewayType"
								typelist="gatewayTypes"
								(update)="runupdate()"></filter-type>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								[objtype]="objtype"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
					</ng-container>

					<!-- GEOFENCE FILTERS -->
					<ng-container *ngIf="isType(ProfileType.GEOFENCE)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.site }">
							<h3 translate="FILTER_BY_SITE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.site"
								[searchtype]="ProfileType.SITE"
								(update)="runupdate()"
								placeholder="SITE"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.geocoordinates }">
							<h3 translate="FILTER_BY_GEOCOORDINATES"></h3>
							<filter-geocoordinates
								#filter
								[(filter)]="filters.geocoordinates"
								(update)="runupdate()"></filter-geocoordinates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
					</ng-container>

					<!-- JOURNEY FILTERS -->
					<ng-container *ngIf="isType(ProfileType.JOURNEY)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.payload }">
							<h3 translate="FILTER_BY_PAYLOAD"></h3>
							<filter-search
								#filter
								[(filter)]="filters.payload"
								[searchtype]="ProfileType.PAYLOAD"
								placeholder="PAYLOADS"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.steps }">
							<h3 translate="FILTER_BY_STEPS"></h3>
							<filter-search
								#filter
								[(filter)]="filters.steps"
								[searchtype]="ProfileType.EXPERIENCE"
								placeholder="EXPERIENCES"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								type="journeys"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>

					<!-- NFC FILTERS -->
					<ng-container *ngIf="isType(ProfileType.NFC)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter
							class="fm-filter"
							[ngClass]="{ active: filters.asset }"
							*fmSetting="'asset-tracking'">
							<h3 translate="FILTER_BY_ASSET"></h3>
							<filter-search
								#filter
								[(filter)]="filters.asset"
								[searchtype]="ProfileType.ASSET"
								placeholder="ASSETS"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.site }">
							<h3 translate="FILTER_BY_SITE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.site"
								[searchtype]="ProfileType.SITE"
								placeholder="SITES"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter
							class="fm-filter"
							[ngClass]="{ active: filters.zone }"
							*fmSetting="'smart-connect'">
							<h3 translate="FILTER_BY_ZONE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.zone"
								[searchtype]="ProfileType.ZONE"
								placeholder="ZONES"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.geocoordinates }">
							<h3 translate="FILTER_BY_GEOCOORDINATES"></h3>
							<filter-geocoordinates
								#filter
								[(filter)]="filters.geocoordinates"
								(update)="runupdate()"></filter-geocoordinates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.division }">
							<h3 translate="FILTER_BY_DIVISION"></h3>
							<filter-search
								#filter
								[(filter)]="filters.division"
								[searchtype]="ProfileType.DIVISION"
								placeholder="DIVISIONS"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.nfcType }">
							<h3 translate="FILTER_BY_TYPE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.nfcType"
								api="nfctypes"
								prop="nfcType"
								placeholder="TYPE"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.attributes }">
							<h3 translate="FILTER_BY_ATTRIBUTE"></h3>
							<filter-attributes
								#filter
								[(filter)]="filters.attributes"
								(update)="runupdate()"
								type="nfc"></filter-attributes>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								type="nfcs"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>

					<!-- PARTNEr FILTERS -->
					<ng-container *ngIf="isType(ProfileType.PARTNER)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
					</ng-container>

					<!-- PAYLOAD FILTERS -->
					<ng-container *ngIf="isType(ProfileType.PAYLOAD)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.type }">
							<h3 translate="FILTER_BY_CONTENT"></h3>
							<filter-select
								#filter
								[(filter)]="filters.type"
								(update)="runupdate()"
								[options]="payloadTypes"></filter-select>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.action }">
							<h3 translate="FILTER_BY_ENGAGEMENT"></h3>

							<filter-select
								#filter
								[(filter)]="filters.action"
								(update)="runupdate()"
								[options]="payloadActions"></filter-select>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								type="payloads"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>

					<!-- PROGRAM FILTERS -->
					<ng-container *ngIf="isType(ProfileType.PROGRAM)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
					</ng-container>

					<!-- PUSH FILTERS -->
					<ng-container *ngIf="isType(ProfileType.PUSH)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.apps }">
							<h3 translate="FILTER_BY_APPS"></h3>
							<filter-search
								#filter
								[(filter)]="filters.apps"
								[searchtype]="ProfileType.APP"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.state }">
							<h3 translate="FILTER_BY_STATE"></h3>
							<filter-states
								#filter
								[(filter)]="filters.state"
								api="states"
								(update)="runupdate()"></filter-states>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								type="pushes"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>

					<!-- SITE FILTERS -->
					<ng-container *ngIf="isType(ProfileType.SITE)">
						<fm-filter
							class="fm-filter"
							*ngIf="partnerList.length"
							[ngClass]="{ active: filters.partners }"
							[defaultOpen]="true">
							<filter-partners
								#filter
								[partners]="partnerList"
								[(filter)]="filters.partners"
								(update)="runupdate()"></filter-partners>
						</fm-filter>
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.siteType }">
							<h3 translate="FILTER_BY_TYPE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.siteType"
								api="sitetypes"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.assets }">
							<h3 translate="FILTER_BY_ASSET"></h3>
							<filter-search
								#filter
								[(filter)]="filters.assets"
								[searchtype]="ProfileType.ASSET"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.region }">
							<h3 translate="FILTER_BY_REGION"></h3>
							<filter-search
								#filter
								[(filter)]="filters.region"
								[searchtype]="ProfileType.REGION"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.district }">
							<h3 translate="FILTER_BY_DISTRICT"></h3>
							<filter-search
								#filter
								[(filter)]="filters.district"
								[searchtype]="ProfileType.DISTRICT"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.divisions }">
							<h3 translate="FILTER_BY_DIVISION"></h3>
							<filter-search
								#filter
								[(filter)]="filters.divisions"
								[searchtype]="ProfileType.DIVISION"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.state }">
							<h3 translate="FILTER_BY_STATE"></h3>
							<filter-states
								#filter
								[(filter)]="filters.state"
								api="states"
								(update)="runupdate()"></filter-states>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.geocoordinates }">
							<h3 translate="FILTER_BY_GEOCOORDINATES"></h3>
							<filter-geocoordinates
								#filter
								[(filter)]="filters.geocoordinates"
								(update)="runupdate()"></filter-geocoordinates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.attributes }">
							<h3 translate="FILTER_BY_ATTRIBUTE"></h3>
							<filter-attributes
								#filter
								[(filter)]="filters.attributes"
								(update)="runupdate()"
								type="site"></filter-attributes>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								type="sites"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>

					<!-- TASK FILTERS -->
					<ng-container *ngIf="isType(ProfileType.TASK)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								type="tasks"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>

					<!-- TASKINSTANCES FILTERS -->
					<ng-container *ngIf="isType(ProfileType.TASKINSTANCES)">
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.task }">
							<h3 translate="FILTER_BY_TASK"></h3>
							<filter-search
								#filter
								[(filter)]="filters.task"
								[searchtype]="ProfileType.TASK"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.site }">
							<h3 translate="FILTER_BY_SITE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.site"
								[searchtype]="ProfileType.SITE"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter
							class="fm-filter"
							[ngClass]="{ active: filters.zone }"
							*fmSetting="'smart-connect'">
							<h3 translate="FILTER_BY_ZONE"></h3>
							<filter-search
								#filter
								[(filter)]="filters.zone"
								[searchtype]="ProfileType.ZONE"
								(update)="runupdate()"></filter-search>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
					</ng-container>

					<!-- ZONE FILTERS -->
					<ng-container *ngIf="isType(ProfileType.ZONE)">
						<fm-filter
							class="fm-filter"
							*ngIf="partnerList.length"
							[ngClass]="{ active: filters.partners }"
							[defaultOpen]="true">
							<filter-partners
								#filter
								[partners]="partnerList"
								[(filter)]="filters.partners"
								(update)="runupdate()"></filter-partners>
						</fm-filter>
						<fm-filter class="fm-filter fixed">
							<filter-status
								#filter
								[(filter)]="filters.status"
								(update)="runupdate()"></filter-status>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.zoneType }">
							<h3 translate="FILTER_BY_TYPE"></h3>
							<filter-type
								#filter
								[(filter)]="filters.zoneType"
								typelist="zoneTypes"
								(update)="runupdate()"></filter-type>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.attributes }">
							<h3 translate="FILTER_BY_ATTRIBUTE"></h3>
							<filter-attributes
								#filter
								[(filter)]="filters.attributes"
								(update)="runupdate()"
								type="zone"></filter-attributes>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: hasDateFilter(filters) }">
							<h3 translate="FILTER_BY_DATE"></h3>
							<filter-dates
								#filter
								[(filter)]="filters"
								(update)="runupdate()"></filter-dates>
						</fm-filter>
						<fm-filter class="fm-filter" [ngClass]="{ active: filters.tags }">
							<h3 translate="FILTER_BY_TAGS"></h3>
							<filter-tags
								#filter
								[(filter)]="filters.tags"
								type="zones"
								(update)="runupdate()"></filter-tags>
						</fm-filter>
					</ng-container>
				</div>
				<a
					class="filter-save"
					*ngIf="isFiltered()"
					[routerLink]=""
					(click)="saveFilter()"
					translate="SAVE_SEARCH"></a>
			</div>
		</div>
	}
</section>
