<div class="field-help">
	@if (!showTooltip) {
		<div class="tooltip-text" [translate]="helpText"></div>
	}
	@if (showTooltip) {
		<div class="tooltip-text">
			<div [innerHTML]="tooltip.tooltip"></div>
			@if (tooltip.link) {
				<a target="_blank" (click)="viewLink(tooltip.link)">learn more</a>
			}
		</div>
	}
	@if (global.user.isSuper) {
		<a class="tooltip-edit" (click)="openTooltipEditor()"
			>Edit
			<span class="tooltip-draft" *ngIf="tooltip?.tooltip">-- published</span>
			<span class="tooltip-draft" *ngIf="!tooltip?.tooltip && tooltip?.draft">-- draft</span>
		</a>
	}
</div>
