<div class="form-row form-name-input">
	<div class="fm-setting">
		<div class="field-text">
			<label class="label" translate="NAME"></label>
			<div class="field-help" translate="NAME_HELP"></div>
		</div>

		@if (view.checkUnique) {
			<div class="field-editor">
				<input
					id="objname"
					name="objname"
					[(ngModel)]="obj.name"
					#objname="ngModel"
					class="fm-input"
					type="text"
					placeholder="{{ 'NAME' | translate }}"
					[readonly]="obj.isReadonly || view.lockname"
					minlength="3"
					[fmExists]="{ type: objtype, field: 'name' }"
					autocomplete="nope"
					required />
				<input-status class="input-status" [obj]="objname"></input-status>

				@if (objname?.errors) {
					<div class="error-message">
						<div *ngIf="objname.errors.required" translate="REQUIRED_FIELD"></div>
						<div *ngIf="objname.errors.minlength" translate="NAME_LENGTH"></div>
						<div *ngIf="objname.errors.exists" translate="NAME_EXISTS"></div>
					</div>
				}
			</div>
		}

		@if (!view.checkUnique) {
			<div class="field-editor">
				<input
					id="objname"
					name="objname"
					[(ngModel)]="obj.name"
					#objname="ngModel"
					class="fm-input"
					type="text"
					placeholder="{{ 'NAME' | translate }}"
					[readonly]="obj.isReadonly || view.lockname"
					minlength="3"
					autocomplete="nope"
					required />
				<input-status class="input-status" [obj]="objname"></input-status>
				<div *ngIf="objname && objname.errors" class="error-message">
					<div *ngIf="objname.errors.required" translate="REQUIRED_FIELD"></div>
					<div *ngIf="objname.errors.minlength" translate="NAME_LENGTH"></div>
				</div>
			</div>
		}
	</div>
</div>

<div class="form-row">
	<div class="fm-setting">
		<div class="field-text">
			<label class="label" translate="DESCRIPTION"></label>
			<div class="field-help" translate="DESCRIPTION_HELP"></div>
		</div>
		<div class="field-editor" *ngIf="!obj.isReadonly && view.showEditor">
			<textarea
				name="description"
				class="fm-textarea"
				placeholder="{{ 'DESCRIPTION' | translate }}"
				#description="ngModel"
				[(ngModel)]="obj.description"></textarea>
			<span class="field-message" *ngIf="obj.description?.length"
				>{{ obj.description?.length | emptyNumber }} {{ 'CHARACTERS' | translate }}
			</span>
			<input-status class="input-status" [obj]="description"></input-status>
		</div>
		<div class="field-editor" *ngIf="obj.isReadonly || !view.showEditor">
			<div
				class="item-description"
				*ngIf="obj.description"
				[innerText]="obj.description"></div>
			<a
				[routerLink]=""
				class="edit-toggle"
				(click)="showEditor()"
				*ngIf="!obj.isReadonly && (!obj.description || !obj.description.length)"
				translate="ADD_DESCRIPTION"></a>
			<a
				[routerLink]=""
				class="edit-toggle"
				(click)="showEditor()"
				*ngIf="!obj.isReadonly && obj.description && obj.description.length"
				translate="EDIT_DESCRIPTION"></a>
		</div>
	</div>
</div>

<ng-content></ng-content>

@if (obj.id) {
	<profile-metadata>
		<ul>
			<li class="form-row" *ngIf="!isNullDate(obj.lastSeen)">
				<label class="label" translate="LAST_SEEN"></label>
				<div class="field-info">
					<span [innerText]="obj.lastSeen | customDateTime"></span> (<span
						translate="UTC_TIME"></span
					>)
				</div>
			</li>

			@if (!isNullDate(obj.lastSeenByApp)) {
				<li class="form-row" [ngClass]="{ clickable: seenApp }" (click)="goToApp()">
					<label class="label" translate="LAST_SEEN_BY_APP"></label>
					<div class="field-info">
						<span [innerText]="lastSeenApp"></span>
					</div>
					<div class="field-info">
						<span [innerText]="obj.lastSeenByApp | customDateTime"></span> (<span
							translate="UTC_TIME"></span
						>)
					</div>
				</li>
			}

			<li
				class="form-row"
				*ngIf="!isNullDate(obj.lastSeenByGateway)"
				(click)="goToGateway()"
				[ngClass]="{ clickable: seenGateway }">
				<label class="label" translate="LAST_SEEN_BY_GATEWAY"></label>
				<div class="field-info">
					<span [innerText]="lastSeenGateway"></span>
				</div>
				<div class="field-info">
					<span [innerText]="obj.lastSeenByGateway | customDateTime"></span> (<span
						translate="UTC_TIME"></span
					>)
				</div>
			</li>

			<li class="form-row" *ngIf="!isNullDate(obj.lastBatteryUpdate)">
				<label class="label" translate="LAST_BATTERY_UPDATE"></label>
				<div class="field-info">
					<span [innerText]="obj.lastBatteryUpdate | customDateTime"></span> (<span
						translate="UTC_TIME"></span
					>)
				</div>
			</li>
			<li class="form-row" *ngIf="!isNullDate(obj.lastLocationUpdate)">
				<label class="label" translate="LAST_LOCATION_UPDATE"></label>
				<div class="field-info">
					<span [innerText]="obj.lastLocationUpdate | customDateTime"></span> (<span
						translate="UTC_TIME"></span
					>)
				</div>
				<div class="field-info">
					Accuracy <span [innerText]="obj.lastLocationHA"></span> Meters
				</div>
			</li>
		</ul>
		<ul>
			<li class="form-row clickable" (click)="loadAudits('insert')">
				<label class="label" translate="CREATED"></label>
				<ng-container *ngIf="audits?.created">
					<div class="field-info">
						<span [innerText]="audits.created.created | customDateTime"></span>
						(<span translate="UTC_TIME"></span>)
					</div>
					<div class="field-note">
						By : {{ audits.created.adminUserName | emptyString }}
					</div>
				</ng-container>

				<ng-container *ngIf="!audits?.created">
					<div class="field-info">
						<span [innerText]="obj.created | customDateTime"></span> (<span
							translate="UTC_TIME"></span
						>)
					</div>
					<div class="field-note">By : System</div>
				</ng-container>
			</li>

			<li class="form-row clickable" (click)="loadAudits('update')">
				<label class="label" translate="UPDATED"></label>
				<ng-container *ngIf="audits.updated">
					<div class="field-info">
						<span [innerText]="audits.updated.updated | customDateTime"></span>
						(<span translate="UTC_TIME"></span>)
					</div>
					<div class="field-note">
						By : {{ audits?.updated?.adminUserName | emptyString }}
					</div>
				</ng-container>
				<ng-container *ngIf="!audits.updated">
					<div class="field-info">
						<span [innerText]="obj.updated | customDateTime"></span> (<span
							translate="UTC_TIME"></span
						>)
					</div>
					<div class="field-note">By : System</div>
				</ng-container>
			</li>
		</ul>
		<div class="form-id">id : {{ obj.id }}</div>
	</profile-metadata>
}
