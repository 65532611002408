<form #modalForm="ngForm" name="modalForm" class="modal-form">
	<section class="fm-detail-content">
		<div class="form-actions">
			<div class="step-actions">
				<button class="fm-button" (click)="publish()" [disabled]="tooltip.draft === ''">
					Publish
				</button>
			</div>

			<button class="fm-button" (click)="save()" [disabled]="modalForm.invalid">Save</button>
			<!--<a [routerLink]="" (click)="delete()">Delete</a>-->
		</div>

		<div class="form-details">
			<div class="settings-grid">
				<div class="form-row fm-setting">
					<label>Type</label>
					<div class="field-info">{{ tooltip.objType }}</div>
				</div>
				<div class="form-row fm-setting">
					<label>Property</label>
					<div class="field-info">
						{{ tooltip.objProp }}
					</div>
				</div>
			</div>

			<div class="tooltip-editor">
				<div class="tooltip-preview">
					<div class="tooltip-link">
						<label>Link - only display when published</label>
						<input
							name="link"
							placeholder="Url"
							type="url"
							[(ngModel)]="tooltip.link"
							[pattern]="urlPattern"
							class="fm-input" />
					</div>
				</div>

				@if (tooltip.tooltip) {
					<div class="tooltip-preview published">
						<div class="tooltip-status published">Published</div>
						<div [innerHTML]="tooltip.tooltip"></div>

						<div class="tooltip-actions">
							<a (click)="editPublished()">Edit Published</a>
							<a (click)="clearPublished()">Clear Published</a>
						</div>
					</div>
				}

				@if (tooltip.draft || view.showEditor) {
					<div class="tooltip-preview draft">
						<div class="tooltip-status draft">Draft</div>
						<div>
							<ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
							</ngx-editor-menu>
							<ngx-editor
								name="draft"
								[editor]="editor"
								[(ngModel)]="tooltip.draft"
								[disabled]="false"
								[placeholder]="'add tooltip text here...'"
								(ngModelChangeDebounced)="checkDraft()"></ngx-editor>

							<div class="tooltip-actions">
								<a (click)="clearEditor()">Clear Draft</a>
							</div>
						</div>
					</div>
				}

				@if (!tooltip.draft && !view.showEditor) {
					<div class="tooltip-preview">
						<a (click)="showEditor()">New Draft</a>
					</div>
				}
			</div>
		</div>
	</section>
</form>
