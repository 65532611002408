<!-- APP/COMPANY -->
@if (isType(ProfileType.APP) || isType(ProfileType.COMPANY)) {
	<div class="profile-image" [ngClass]="mode">
		<div class="image-wrap">
			@if (obj.image?.pathFragment) {
				<img
					alt="profile image"
					width="100%"
					height="100%"
					[src]="getCdnImage(obj.image)" />
			}
			<fm-icon *ngIf="!obj.image || !obj.image.pathFragment" [type]="objtype"></fm-icon>
		</div>
		@if (showEdit) {
			<upload-cdn-image
				class="image-upload"
				[obj]="obj"
				[objtype]="objtype"></upload-cdn-image>
		}
	</div>
}

<!-- EXPERIENCE/JOURNEY/PUSH -->
@if (isType(ProfileType.EXPERIENCE) || isType(ProfileType.JOURNEY) || isType(ProfileType.PUSH)) {
	<div class="profile-image delivered" [ngClass]="mode">
		<div class="image-wrap">
			<h4 translate="DELIVERED"></h4>
			<div class="detail-info">
				<fm-delivered [obj]="obj"></fm-delivered>
			</div>
		</div>
	</div>
}

<!-- ADMINUSER -->
@if (isType(ProfileType.ADMINUSER)) {
	<div class="profile-image" [ngClass]="mode">
		<div class="image-wrap">
			<img
				alt="profile image"
				width="100%"
				height="100%"
				*ngIf="obj.image"
				[src]="obj.image" />
			<div *ngIf="!obj.image && obj.firstname && obj.lastname" class="text">
				{{ obj.firstname.charAt(0) }}{{ obj.lastname.charAt(0) }}
			</div>
			<fm-icon
				*ngIf="!obj.image && (!obj.firstname || !obj.lastname)"
				[type]="objtype"></fm-icon>
		</div>
		@if (showEdit) {
			<upload-cdn-image
				class="image-upload"
				[obj]="obj"
				[objtype]="objtype"></upload-cdn-image>
		}
	</div>
}

<!-- ASSET -->
@if (isType(ProfileType.ASSET)) {
	<div class="profile-image" [ngClass]="mode">
		<div class="image-wrap">
			<img
				alt="profile image"
				width="100%"
				height="100%"
				*ngIf="obj?.images?.length"
				[src]="getCdnImage(obj.images[0])" />
			<fm-icon *ngIf="!obj || !obj.images || !obj.images.length" [type]="objtype"></fm-icon>
		</div>
		@if (showEdit) {
			<upload-cdn-image
				class="image-upload"
				[obj]="obj"
				[objtype]="objtype"></upload-cdn-image>
		}
	</div>
}

<!-- BEACON -->
@if (isType(ProfileType.BEACON)) {
	<div class="profile-image" [ngClass]="mode">
		<div class="image-wrap">
			<img
				alt="profile image"
				width="100%"
				height="100%"
				*ngIf="obj.beaconType"
				[src]="getBeaconImage(obj.beaconType)" />
			<fm-icon *ngIf="!obj.beaconType" [type]="objtype"></fm-icon>
		</div>
	</div>
}

<!-- NFC -->
@if (isType(ProfileType.NFC)) {
	<div class="profile-image" [ngClass]="mode">
		<div class="image-wrap">
			<img
				alt="profile image"
				width="100%"
				height="100%"
				*ngIf="obj.nfcType"
				[src]="getNFCImage(obj.nfcType)" />
			<fm-icon *ngIf="!obj.nfcType" [type]="objtype"></fm-icon>
		</div>
	</div>
}

<!-- PAYLOAD -->
@if (isType(ProfileType.PAYLOAD)) {
	<div class="profile-image" [ngClass]="mode">
		<div class="image-wrap">
			<payload-preview
				class="payload-preview"
				*ngIf="obj.type"
				[payload]="obj"></payload-preview>
			<fm-icon *ngIf="!obj.type" [type]="objtype"></fm-icon>
		</div>
	</div>
}

<!-- GATEWAY -->
@if (isType(ProfileType.GATEWAY)) {
	<div class="profile-image" [ngClass]="mode">
		<div class="image-wrap">
			@if (!obj?.images?.length) {
				<i
					class="fm-icon fa-solid fa-bezier-curve"
					*ngIf="obj.gatewayType === GatewayType.STANDARD"></i>
				<i
					class="fm-icon fa-solid fa-router"
					*ngIf="obj.gatewayType === GatewayType.LORAWANGATEWAY"></i>
				<i
					class="fm-icon fa-solid fa-circle-nodes"
					*ngIf="obj.gatewayType === GatewayType.LORAWANCOLLECTOR"></i>
			}

			@if (obj?.images?.length) {
				<img
					alt="profile image"
					width="100%"
					height="100%"
					[src]="getCdnImage(obj.images[0])" />
			}
		</div>
		@if (showEdit) {
			<upload-cdn-image
				class="image-upload"
				[obj]="obj"
				[objtype]="objtype"
				[saveImages]="saveImages"></upload-cdn-image>
		}
	</div>
}
<!-- DEVICE/TASK/ZONE -->
<div
	class="profile-image"
	[ngClass]="mode"
	*ngIf="
		isType(ProfileType.DEVICE) ||
		isType(ProfileType.PROGRAM) ||
		isType(ProfileType.TASK) ||
		isType(ProfileType.ZONE)
	">
	<div class="image-wrap">
		<fm-icon [type]="objtype"></fm-icon>
	</div>
</div>

<!-- GEOFENCE -->
@if (isType(ProfileType.GEOFENCE)) {
	<div class="profile-image" [ngClass]="mode">
		<div class="image-wrap">
			<img
				alt="profile image"
				width="100%"
				height="100%"
				*ngIf="hasLatLng()"
				[src]="getMap('G')" />
			<fm-icon *ngIf="!hasLatLng()" [type]="objtype"></fm-icon>
		</div>
	</div>
}

<!-- SITE -->
@if (isType(ProfileType.SITE)) {
	<div class="profile-image" [ngClass]="mode">
		<div class="image-wrap">
			<img
				alt="profile image"
				width="100%"
				height="100%"
				*ngIf="hasLatLng()"
				[src]="getMap('S')" />
			<fm-icon *ngIf="!hasLatLng()" [type]="objtype"></fm-icon>
			<!--
			<div class="text">
				{{obj.state | stateAbbrev}}
			</div>
			-->
		</div>

		@if (showEdit) {
			<div class="image-upload" (click)="goToState('location')">Edit Location</div>
		}
	</div>
}
