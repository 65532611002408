<div *ngIf="isBusy" class="image-loading">
	<div translate="UPLOADING"></div>
	<fm-loader [type]="'ellipsis'"></fm-loader>
</div>
<div class="edit-link" *ngIf="!isBusy">
	<label for="cdnUpload" translate="UPLOAD_IMAGE"></label>
	<input
		type="file"
		id="cdnUpload"
		style="display: none"
		accept="image/*"
		(change)="uploadImages($event)" />
	<div class="note"><span translate="UPLOAD_IMAGE_MESSAGE"></span></div>
</div>
