@if (isBasic) {
	<router-outlet #routerOutlet="outlet"></router-outlet>
}

@if (!isBasic && global) {
	@if (view.showLogin) {
		<login class="login-page"></login>
	}

	@if (view.showMain) {
		<section class="site-main" @openComp>
			<fm-header></fm-header>

			<!-- SITE CONTENT -->
			<section class="fm-container">
				<main-menu class="site-nav" [class]="{ active: view.showMainMenu }"></main-menu>
				<div class="site-content">
					<router-outlet #routerOutlet="outlet"></router-outlet>
				</div>
			</section>

			@if (view.showNews) {
				<!-- CURRENT UPDATES -->
				<section class="fm-popup">
					<div class="popup-bkgd"></div>
					<fm-news [view]="view"></fm-news>
				</section>
			}

			<!-- COMPANY SWITCH -->
			@if (changingCompany) {
				<section class="fm-popup">
					<div class="popup-bkgd"></div>
					<div class="popup-content">
						<div class="popup-header">
							<h2 translate="SWITCHING_COMPANIES"></h2>
						</div>
						<div class="popup-body">
							<div class="switch-wrap" [ngClass]="{ changed: newCompany }">
								<div class="company-switcher">
									<profile-image
										[objtype]="ProfileType.COMPANY"
										[obj]="global.company"></profile-image>
									<h4 [innerText]="global.company.name"></h4>
								</div>
								<div class="company-switcher" *ngIf="newCompany">
									<profile-image
										[objtype]="ProfileType.COMPANY"
										[obj]="newCompany"></profile-image>
									<h4 [innerText]="newCompany.name"></h4>
								</div>
							</div>
						</div>
						<div class="popup-footer">
							<h4 translate="LOADING"></h4>
							<i class="fa fa-circle-notch fa-spin"></i>
						</div>
					</div>
				</section>
			}
		</section>
	}
}
